// PlayArea.tsx
import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import { useSocket } from '../../Context/SocketContext';
import PlayBox from '../../components/PlayBox/PlayBox';
import { io } from 'socket.io-client';

const PlayArea = () => {
    const { message } = useSocket();
    // const [socket, setSocket] = useState<any>(null);
    // const [message, setMessage] = useState<any>();
    // useEffect(() => {
    //     const url: string =
    //         process.env.REACT_APP_API_SOCKET_CONNECTION ||
    //         'https://starfish-app-cnmgd.ondigitalocean.app';
    //     const newSocket: any = io(
    //         url
    //     );

    //     newSocket.on('onMessage', (msg: any, serverOffset: any) => {
    //         console.log('Message received:', msg);
    //         setMessage(msg);
    //         // newSocket.auth.serverOffset = serverOffset;
    //     });

    //     setSocket(newSocket);

    //     return () => {
    //         newSocket.off('onMessage'); // Add this to properly remove the event listener
    //         newSocket.close();
    //     };
    // }, []);
    // console.log('Play Area Message:', message);

    const [key, setKey] = useState(0); // State to force re-render

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setKey((prevKey) => prevKey + 1); // Increment key to trigger re-render
    //     }, 5000); // 5000ms = 5 seconds

    //     return () => clearInterval(interval); // Clear interval on component unmount
    // }, []);

    return (
        <Layout>
            {/* Render your component logic here */}
            <PlayBox message={message} key={key} />
            {/* <PlayBox message={message} /> */}
        </Layout>
    );
};

export default PlayArea;
