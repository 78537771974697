import { useNavigate } from 'react-router-dom';
import Alert from '../../assets/Alert';
import AlertSection from '../../components/Common/AlertSection/AlertSection';
import PlayerHeader from '../../components/Common/PlayerHeader';
import Layout from '../../components/Common/Layout/Layout';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import HowPlayVideo from '../../components/HowPlayVideo/HowPlayVideo';
import { useGetAllBattlesTypes } from '../../apis/hooks/useBattlesType';
import Loader from '../../components/Common/Loader/Loader';
import { io } from 'socket.io-client';
import { useUserData } from '../../Context/UserContext';
import { MdInfo } from 'react-icons/md';

const LandingPage = () => {
    const navigate = useNavigate();
    const { user } = useUserData();

    const [isOpenGuide, setIsOpenGuide] = useState<boolean>(false);
    const { data: battlesType, isLoading } = useGetAllBattlesTypes();

    // const [message, setMessage] = useState('');
    // const [messages, setMessages] = useState<any>([]);
    // const [counter, setCounter] = useState(0);
    // const [socket, setSocket] = useState<any>(null);

    // useEffect(() => {
    //     const newSocket: any = io(
    //         // 'https://starfish-app-cnmgd.ondigitalocean.app',
    //         'http://localhost:8080'
    //         // {
    //         //     ackTimeout: 10000,
    //         //     // retries: 3,
    //         //     auth: {
    //         //         serverOffset: 0,
    //         //     },
    //         // }
    //     );

    //     newSocket.on('onMessage', (msg: any, serverOffset: any) => {
    //         console.log('Message received:', msg);
    //         // setMessages((prevMessages: any) => [...prevMessages, msg]);
    //         // newSocket.auth.serverOffset = serverOffset;
    //     });

    //     setSocket(newSocket);

    //     return () => {
    //         newSocket.off('onMessage'); // Add this to properly remove the event listener
    //         newSocket.close();
    //     };
    // }, []);

    // const sendMessage = (e: any) => {
    //     e.preventDefault();
    //     if (message.trim() && socket) {
    //         const clientOffset = `${socket.id}-${counter}`;
    //         socket.emit('onMessage', { msg: message, clientOffset });
    //         setMessage('');
    //         setCounter(counter + 1);
    //     }
    // };

    const handleGameClick = (game: any) => {
        if (game?.battlesType === 'Classic') {
            navigate(`/battle/${game?.battlesType}/${game?.id}`);
        }
    };

    const renderGameCards = () => {
        if (battlesType?.length === 0) {
            return <p>No games available at the moment.</p>;
        }
        return battlesType?.map((type: any, index: number) => (
            <div
                key={index}
                onClick={() => handleGameClick(type)}
                className="relative cursor-pointer"
            >
                {type?.battlesType !== 'Classic' && (
                    <div className="ribbon ribbon-top-right">
                        <span>Coming Soon</span>
                    </div>
                )}
                <img
                    loading="lazy"
                    src={type?.files?.fullUrl}
                    className={`rounded-lg ${type?.battlesType !== 'Classic' ? '' : ''}`}
                    alt={`${type?.battlesType} game`}
                />
            </div>
        ));
    };

    return (
        <Layout>
            <section className="w-[90%] mx-auto">
                <AlertSection
                    className="p-2 bg-yellow-100"
                    alertType="WARNING"
                    message="NOTICE: सभी यूजर ध्यान दें WIN होने के बाद रूम कोड वाला स्क्रीनशॉट ही डालें अन्यथा आपकी टेबल CANCEL कर दी जाएगी. LOSS होने पर LOSS नहीं डाला या ग़लत अपडेट किया तो 250 Rs कि पेनल्टी लगा दी जायगी."
                />
                <br />
                {/* <AlertSection
                    className="p-2 bg-red-300"
                    alertType="WARNING"
                    message="NOTICE: वेबसाइट पर काम चल रहा है, अभी गेम ना खेले"
                />
                <br /> */}

                {/* <div>
                    <ul>
                        {messages.map((msg: any, index: any) => (
                            <li key={index}>{msg}</li>
                        ))}
                    </ul>
                    <form onSubmit={sendMessage}>
                        <input
                            style={{ color: 'red' }}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            autoComplete="off"
                        />
                        <button type="submit">Send</button>
                    </form>
                </div> */}
                {!user?.userdetails?.kyc ? (
                    <div className="flex items-center justify-between p-2 font-semibold bg-gray-300 rounded-lg">
                        <div className="flex">
                            <p className="text-center text-red-500">
                                KYC Pending
                            </p>
                            <MdInfo color="red" size={24} />
                        </div>
                        <button
                            className="p-2 text-white bg-red-600 rounded-lg cursor-pointer"
                            onClick={() => navigate('/kyc')}
                        >
                            Complete Here
                        </button>
                    </div>
                ) : (
                    <></>
                )}

                <div className="flex items-center justify-between gap-1 mt-2 mb-6">
                    <span className="text-[32px] font-medium">Games</span>
                    <button
                        className="cursor-pointer flex gap-1 text-[#0d6efd] hover:text-white hover:bg-[#0d6efd] w-[93.37] h-[38px] border border-[#0d6efd] items-center px-2 rounded-md leading-loose"
                        onClick={() => setIsOpenGuide(true)}
                    >
                        <Alert className="w-[16px]" />
                        Guide
                    </button>
                </div>
                {isLoading ? (
                    <div className="flex flex-col items-center justify-center gap-8 h-[300px]">
                        <Loader scale={4} />
                        <p>Please wait. Loading...</p>
                    </div>
                ) : (
                    <div className="grid grid-cols-2 gap-2">
                        {renderGameCards()}
                    </div>
                )}
            </section>
            {isOpenGuide && (
                <HowPlayVideo
                    isOpen={isOpenGuide}
                    onClose={() => setIsOpenGuide(false)}
                />
            )}
        </Layout>
    );
};

export default LandingPage;
