import { useMutation, useQuery } from '@tanstack/react-query';
import { userFetcher } from '../fetcher/fetcher';
import { Client } from '..';
import { API_PATHS } from '../constants';

type Props = {
    data: any;
    isLoading: boolean;
    mutate: any;
    error: any;
};

const useUser = (): Props => {
    const { data, isLoading, mutate, error } = useMutation(
        ['user-details'],
        userFetcher
    );

    return {
        data: data,
        isLoading,
        mutate,
        error,
    };
};

export default useUser;

export const useUpdateUserDetails = () => {
    const { isLoading, mutate } = useMutation(
        ['update-user-details'],
        (data: any) => {
            return Client.put(
                API_PATHS.UPDATE_USER.replace('{id}', data.id),
                data
            );
        }
    );
    return {
        isLoading,
        mutate,
    };
};
