// SocketContext.tsx
import { useEffect, useState, useContext, createContext } from 'react';
import { io, Socket } from 'socket.io-client';

const SocketContext = createContext<any>(null);

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }: any) => {
    const [message, setMessage] = useState(null);
    const [walletMessage, setWalletMessage] = useState(null);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [walletSocket, setWalletSocket] = useState<Socket | null>(null);

    useEffect(() => {
        // const url: any = process.env.REACT_APP_API_SOCKET_CONNECTION + "/battles";

        // Replace with your backend WebSocket URL
        const url: string =
            process.env.REACT_APP_API_SOCKET_CONNECTION ||
            'https://starfish-app-cnmgd.ondigitalocean.app';

        console.log(`Socket URL: ${url}`);

        const socketInstance = io(url, {
            // const socketInstance = io(
            // 'http://starfish-app-cnmgd.ondigitalocean.app',
            // {
            transports: ['websocket', 'polling'],
            reconnection: true, // Enable reconnection
            reconnectionAttempts: 5, // Try to reconnect up to 5 times
            reconnectionDelay: 1000, // Delay before trying to reconnect (1 second)
            // reconnectionDelayMax: 5000, // Maximum delay between reconnections (5 seconds)
            // timeout: 20000,
            // ackTimeout: 10000,
            // retries: 3,
            // auth: {
            //     serverOffset: 0,
            // },
        });

        const walletSocketInstance = io(`${url}/wallet`, {
            transports: ['websocket', 'polling'],
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });

        socketInstance.on('connect', () => {
            console.log('Socket connected:', socketInstance.id);
        });

        // socketInstance.on('disconnect', (reason) => {
        //     console.log(`Socket disconnected: ${reason}`);
        // });

        walletSocketInstance.on('connect', () => {
            console.log('Connected to /wallet:', walletSocketInstance.id);
        });

        socketInstance.on('onMessage', (newMessage) => {
            console.log('New message received:', newMessage);
            setMessage(newMessage);
        });

        walletSocketInstance.on('onWalletMessage', (newMessage) => {
            console.log('Wallet Socket message received:', newMessage);
            setWalletMessage(newMessage);
        });

        setSocket(socketInstance);
        setWalletSocket(walletSocketInstance);

        return () => {
            // socketInstance.off('connect');
            // socketInstance.off('disconnect');
            // socketInstance.off('onMessage');
            socketInstance.disconnect();
            // socketInstance.close();

            walletSocketInstance.disconnect();
        };
    }, []);

    return (
        <SocketContext.Provider
            value={{ socket, message, walletMessage, walletSocket }}
        >
            {children}
        </SocketContext.Provider>
    );
};
