import React from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes/routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { queryClientConfig } from './Config/queryClient';
import { UserContext, UserProvider } from './Context/UserContext';
import { SocketProvider } from './Context/SocketContext';

type AppProps = {};

const App: React.FC<AppProps> = () => {
    return (
        <QueryClientProvider client={new QueryClient(queryClientConfig)}>
            <BrowserRouter>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    pauseOnFocusLoss
                    pauseOnHover
                    theme="colored"
                />
                <UserProvider>
                    <SocketProvider>
                        <Routes />
                    </SocketProvider>
                </UserProvider>
            </BrowserRouter>
        </QueryClientProvider>
    );
};

export default App;
