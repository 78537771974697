import { useNavigate } from 'react-router-dom';
import User from '../../assets/UserProfile';
import PlayerHeader from '../../components/Common/PlayerHeader';
import { useEffect, useRef, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import { json } from 'stream/consumers';
import { Controller, Form, useForm } from 'react-hook-form';
import { useUserData } from '../../Context/UserContext';
import { MdDone, MdEdit, MdInfo } from 'react-icons/md';
import { useUploadFiles } from '../../apis/hooks/useUploadFiles';
import { toast } from 'react-toastify';
import { useUpdateUserDetails } from '../../apis/hooks/useUser';
import Loader from '../../components/Common/Loader/Loader';
import FilePreview from '../../components/Common/FilePreview/FilePreview';
import { useGetKyc } from '../../apis/hooks/useKYC';

const Profile = () => {
    const navigate = useNavigate();
    const { user, fetchUser } = useUserData();
    const { mutate: uploadFiles, isLoading: fileUploading } = useUploadFiles();
    const { data: kycData, isLoading } = useGetKyc({
        userId: user?.userdetails?.id,
    });

    const { mutate: updateUser, isLoading: updatingUser } =
        useUpdateUserDetails();
    const fileInputRef = useRef<any>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const {
        handleSubmit,
        control,
        register,
        reset,
        formState: { errors },
        setValue,
    } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            username: user?.userdetails?.username,
            mobileNumber: user?.userdetails?.mobileNumber,
            profileImageId: user?.userdetails?.profileImageId ?? '',
            emailId: user?.userdetails?.emailId ?? '',
        },
    });

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            uploadFiles(formData, {
                onSuccess: (data: any) => {
                    if (data?.data?.id) {
                        toast('File uploaded', { type: 'success' });
                        // Clear the file input value
                        fileInputRef.current.value = '';
                        setValue('profileImageId', data?.data?.id);
                        // fetchUser();
                        // reset({ profileImage: data?.data?.url });
                    }
                },
                onError: (error: any) => {
                    toast('Something went wrong', { type: 'error' });
                },
            });
        }
    };

    const onSubmit = (formData: any) => {
        updateUser(
            {
                id: user?.userdetails?.id,
                username: formData?.username,
                // mobileNumber: +formData?.mobileNumber,
                profileImageId: formData?.profileImageId,
                emailId: formData?.emailId,
            },
            {
                onSuccess: (data: any) => {
                    if (data?.data?.success) {
                        toast(data?.data?.message ?? 'Profile updated', {
                            type: 'success',
                        });
                        fetchUser();
                    }
                },
                onError: (error: any) => {
                    toast(
                        Array.isArray(error?.message)
                            ? error?.message[0]
                            : error?.message ?? 'Something went wrong',
                        { type: 'error' }
                    );
                },
            }
        );
    };

    const Logout = () => {
        localStorage.clear();
        navigate('/login');
    };

    return (
        <Layout>
            <div className="mt-[20px] flex flex-col justify-center items-center">
                <div className="border rounded-xl w-[90%]">
                    <span className="flex justify-center w-full bg-[#F8F9FA] py-2 px-4  text-center border-b">
                        Profile
                    </span>
                    <div className="flex flex-col gap-2 p-4 border border-t-0 shadow-lg">
                        <div className="flex">
                            {user?.userdetails?.profileImageData?.id ? (
                                <div className="flex flex-col w-full">
                                    <div className="flex items-center justify-between gap-2">
                                        <img
                                            src={
                                                user?.userdetails
                                                    ?.profileImageData?.fullUrl
                                            }
                                            alt="profile image"
                                            className="w-[100px] h-[100px] rounded-full"
                                            onClick={() => setIsOpen(true)}
                                        />
                                        <button
                                            className={`text-[#fff] rounded-full p-2 px-4 ${fileUploading ? 'cursor-not-allowed bg-gray-400 animate-pulse' : 'cursor-pointer bg-[#0d6efd]'}`}
                                            onClick={() =>
                                                fileInputRef.current?.click()
                                            }
                                        >
                                            {!fileUploading
                                                ? 'Upload Profile'
                                                : 'Uploading...'}
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col w-full">
                                    <div className="flex items-center justify-between gap-2">
                                        <User className="w-[100px] h-[100px]" />
                                        <button
                                            className={`text-[#fff] rounded-full p-2 ${fileUploading ? 'cursor-not-allowed bg-gray-400 animate-pulse' : 'cursor-pointer bg-[#0d6efd]'}`}
                                            onClick={() =>
                                                fileInputRef.current?.click()
                                            }
                                        >
                                            {!fileUploading
                                                ? 'Upload Profile'
                                                : 'Uploading...'}
                                        </button>
                                    </div>
                                </div>
                            )}
                            <input
                                ref={fileInputRef}
                                type="file"
                                className="hidden"
                                required
                                data-max-size="4096000"
                                id="upload_ipt"
                                onChange={handleFileChange}
                            />
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col">
                                <span>User Name</span>
                                <Controller
                                    name="username"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({
                                        field: { ref, ...hookFormField },
                                        fieldState,
                                    }) => (
                                        <div>
                                            <input
                                                type="text"
                                                className={`p-2 border rounded focus:outline-none w-full ${fieldState.error ? 'border-red-600' : ''}`}
                                                {...hookFormField}
                                            />
                                            {!!fieldState.error && (
                                                <div className="text-xs text-red-600">
                                                    {fieldState.error?.type ===
                                                    'required'
                                                        ? 'Required field'
                                                        : ''}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col">
                                <span className="">Phone</span>
                                <Controller
                                    name="mobileNumber"
                                    rules={{
                                        required: true,
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message:
                                                'Please enter a valid phone number',
                                        },
                                        minLength: {
                                            value: 10,
                                            message:
                                                'Please enter a minimun 10 digit number',
                                        },
                                        maxLength: {
                                            value: 10,
                                            message:
                                                'Please enter a maximun 10 digit number',
                                        },
                                    }}
                                    control={control}
                                    disabled
                                    render={({
                                        field: { ref, value, ...hookFormField },
                                        fieldState,
                                    }) => (
                                        <div>
                                            <input
                                                type="text"
                                                inputMode="numeric"
                                                value={
                                                    user?.userdetails
                                                        ?.mobileNumber
                                                }
                                                className={`p-2 border rounded focus:outline-none w-full ${fieldState.error ? 'border-red-600' : ''}`}
                                                {...hookFormField}
                                            />
                                            {!!fieldState.error && (
                                                <div className="text-xs text-red-600">
                                                    {fieldState.error.message}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col">
                                <span className="">Email Id</span>
                                <Controller
                                    name="emailId"
                                    control={control}
                                    rules={{
                                        required: true,
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@gmail\.com$/,
                                            message:
                                                'Please enter a valid gmail address.',
                                        },
                                    }}
                                    render={({
                                        field: { ref, ...hookFormField },
                                        fieldState,
                                    }) => (
                                        <div>
                                            <input
                                                type="text"
                                                // value={
                                                //     user?.userdetails?.emailId
                                                // }
                                                className={`p-2 border rounded focus:outline-none w-full ${fieldState.error ? 'border-red-600' : ''}`}
                                                {...hookFormField}
                                            />
                                            {!!fieldState.error && (
                                                <div className="text-xs text-red-600">
                                                    {fieldState.error.message}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <button
                                className="bg-[#0d6efd] text-[#fff] rounded-full p-2 mb-2 mt-4 w-full"
                                type="submit"
                                disabled={updatingUser}
                            >
                                <div className="flex items-center justify-center gap-4">
                                    {updatingUser ? 'Updating...' : 'Update'}
                                    {updatingUser && (
                                        <Loader scale={2} color="white" />
                                    )}
                                </div>
                            </button>
                        </form>
                    </div>
                </div>

                <div className="border rounded-xl w-[90%] mt-4 shadow-lg">
                    <span className="flex justify-center w-full bg-[#F8F9FA] py-2 px-4  text-center border-b">
                        KYC Status
                    </span>
                    {isLoading ? (
                        <div className="flex flex-col items-center justify-center gap-2 p-2">
                            <Loader scale={2} color="blue" />
                            <p className="text-blue-600">Loading...</p>
                        </div>
                    ) : (
                        <div className="grid items-center grid-cols-2 gap-2 p-2 text-center text-md">
                            <div className="text-center rounded-lg">
                                <p className="flex">
                                    {kycData?.status ? (
                                        <>
                                            <span className="font-semibold">
                                                {kycData?.status}
                                            </span>
                                            {kycData?.status === 'Approved' ? (
                                                <MdDone
                                                    color="green"
                                                    size={24}
                                                />
                                            ) : (
                                                <MdInfo color="red" size={24} />
                                            )}
                                        </>
                                    ) : (
                                        <span className="font-semibold text-center text-green-600">
                                            Pending
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className="my-auto text-center ">
                                {kycData?.status !== 'Approved' && (
                                    <button
                                        className="p-2 text-red-600 border border-red-600 rounded-lg"
                                        onClick={() => navigate('/kyc')}
                                    >
                                        Complete KYC
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className="border rounded-xl w-[90%] mt-4 shadow-lg">
                    <span className="flex justify-center w-full bg-[#F8F9FA] py-2 px-4  text-center border-b">
                        Metrics
                    </span>
                    <div className="grid grid-cols-2 gap-2 p-2 text-md">
                        <div className="w-full text-center border rounded-lg">
                            <span className="flex justify-center w-full bg-[#F8F9FA] text-center border-b ">
                                Games Played
                            </span>
                            <div className="my-auto text-center ">
                                {user?.userdetails?.totalGamesPlayed ?? 0}
                            </div>
                        </div>

                        <div className="w-full text-center border rounded-lg">
                            <span className="flex justify-center w-full bg-[#F8F9FA] text-center border-b ">
                                Chips Won
                            </span>
                            <div className="my-auto text-center ">
                                {user?.userdetails?.wallet?.balance ?? 0}
                            </div>
                        </div>

                        <div className="w-full text-center border rounded-lg">
                            <span className="flex justify-center w-full bg-[#F8F9FA] text-center border-b ">
                                Referal Earning
                            </span>
                            <div className="my-auto text-center">
                                {' '}
                                {user?.userdetails?.earnFromReferral ?? 0}
                            </div>
                        </div>

                        <div className="w-full text-center border rounded-lg">
                            <span className="flex justify-center w-full bg-[#F8F9FA] text-center border-b ">
                                Penalty
                            </span>
                            <div className="my-auto text-center">0</div>
                        </div>
                    </div>
                </div>

                <button
                    className=" w-[90%] border border-red-700 text-[red] hover:text-white rounded-lg mt-4 hover:bg-red-700 p-2 text-lg"
                    onClick={() => Logout()}
                >
                    Logout
                </button>
            </div>

            <FilePreview
                open={isOpen}
                onClose={handleClose}
                fileUrl={user?.userdetails?.profileImageData?.fullUrl}
            />
        </Layout>
    );
};

export default Profile;
