import React from 'react';

const RedTriangleSolidWarning = ({ ...props }) => (
    <>
        <svg
            width="50"
            height="20"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.8286 8.33137L6.6916 6.28337C6.6646 5.87137 6.5246 5.20537 6.8386 4.87037C7.0776 4.61237 7.6446 4.57037 7.8176 4.92537C7.96393 5.34472 7.99636 5.79539 7.9116 6.23137L7.7286 8.33937C7.72081 8.53781 7.67745 8.73325 7.6006 8.91637C7.57027 8.97555 7.52435 9.02533 7.4678 9.06032C7.41125 9.09531 7.34621 9.11419 7.27971 9.11491C7.21321 9.11563 7.14778 9.09817 7.09048 9.06441C7.03318 9.03065 6.9862 8.98188 6.9546 8.92337C6.88219 8.7338 6.84001 8.53403 6.8296 8.33137H6.8286ZM7.3026 11.1444C7.16307 11.1437 7.02762 11.0973 6.91692 11.0124C6.80622 10.9275 6.72636 10.8086 6.68953 10.6741C6.6527 10.5395 6.66092 10.3966 6.71295 10.2671C6.76498 10.1376 6.85795 10.0288 6.97767 9.9571C7.09738 9.88544 7.23726 9.85493 7.37594 9.87023C7.51462 9.88553 7.64448 9.94581 7.7457 10.0418C7.84691 10.1379 7.9139 10.2644 7.93646 10.4021C7.95901 10.5398 7.93588 10.6811 7.8706 10.8044C7.81613 10.9077 7.73438 10.9941 7.63424 11.0542C7.5341 11.1143 7.41939 11.1459 7.3026 11.1454V11.1444Z"
                fill="#DC2626"
            />
            <path
                d="M13.0627 13.1593H1.53467C1.26382 13.1551 0.998743 13.0804 0.765565 12.9425C0.532386 12.8047 0.339153 12.6084 0.204909 12.3731C0.0706643 12.1379 4.15038e-05 11.8717 1.82877e-08 11.6008C-4.14673e-05 11.3299 0.0704999 11.0637 0.204672 10.8283L5.97267 1.57735C6.1125 1.35181 6.30759 1.1657 6.53946 1.03664C6.77133 0.907581 7.03231 0.839844 7.29767 0.839844C7.56304 0.839844 7.82401 0.907581 8.05588 1.03664C8.28775 1.1657 8.48284 1.35181 8.62267 1.57735L14.3847 10.8133C14.5222 11.0485 14.5958 11.3155 14.598 11.5879C14.6002 11.8603 14.5309 12.1286 14.3972 12.3659C14.2635 12.6032 14.0699 12.8013 13.8357 12.9406C13.6016 13.0798 13.335 13.1552 13.0627 13.1593ZM6.85567 2.11235L1.08467 11.3593C1.04214 11.4379 1.02062 11.5262 1.02219 11.6155C1.02375 11.7048 1.04836 11.7922 1.09361 11.8693C1.13887 11.9463 1.20325 12.0104 1.28052 12.0552C1.35779 12.1001 1.44533 12.1242 1.53467 12.1253H13.0627C13.1529 12.1238 13.2411 12.0988 13.3188 12.0528C13.3964 12.0068 13.4607 11.9414 13.5054 11.863C13.5501 11.7846 13.5736 11.6959 13.5737 11.6057C13.5737 11.5155 13.5503 11.4268 13.5057 11.3483C13.5057 11.3483 7.74467 2.11535 7.74167 2.11235C7.69431 2.03758 7.62882 1.97601 7.55128 1.93334C7.47374 1.89067 7.38667 1.8683 7.29817 1.8683C7.20967 1.8683 7.1226 1.89067 7.04506 1.93334C6.96753 1.97601 6.90203 2.03758 6.85467 2.11235H6.85567Z"
                fill="#DC2626"
            />
        </svg>
    </>
);

export default RedTriangleSolidWarning;
