import React, { useEffect, useRef, useState } from 'react';
import Layout from '../Common/Layout/Layout';
import Loader from '../Common/Loader/Loader';
import { useForm } from 'react-hook-form';
import { useGetKyc, useSubmitKYC } from '../../apis/hooks/useKYC';
import { useUploadFiles } from '../../apis/hooks/useUploadFiles';
import { toast } from 'react-toastify';
import { MdClose, MdUpload } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../Context/UserContext';

interface IFormInput {
    name: string;
    emailId: string;
    dateOfBirth: Date;
    aadharCardNumber: string;
    frontImageId: string;
    backImageId: string;
}

const Kyc = () => {
    const navigate = useNavigate();
    const { user } = useUserData();

    const { mutate: uploadFile, isLoading: uploading } = useUploadFiles();
    const { data: kycData, isLoading: loading } = useGetKyc({
        userId: user?.userdetails?.id,
    });

    const { mutate, isLoading } = useSubmitKYC({
        kycId: kycData?.id,
    });
    const [frontImagePreview, setFrontImagePreview] = useState<string | null>(
        null
    );
    const [backImagePreview, setBackImagePreview] = useState<string | null>(
        null
    );
    const fileInputRef = useRef<any>(null);
    const backfileInputRef = useRef<any>(null);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<IFormInput>({
        mode: 'onSubmit',
    });
    // Patch the form fields with fetched kycData when it becomes available
    useEffect(() => {
        if (kycData) {
            setValue('name', kycData.name ?? '');
            setValue('emailId', kycData.emailId ?? '');
            setValue('dateOfBirth', kycData.dateOfBirth ?? '');
            setValue('aadharCardNumber', kycData.aadharCardNumber ?? '');
            setValue('frontImageId', kycData.frontImageId ?? '');
            setValue('backImageId', kycData.backImageId ?? '');
            setFrontImagePreview(
                kycData.frontImageId ? kycData.frontImage?.fullUrl : ''
            );
            setBackImagePreview(
                kycData.backImageId ? kycData.backImage?.fullUrl : ''
            );
        }
    }, [kycData, setValue]);

    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>,
        field: 'frontImageId' | 'backImageId'
    ) => {
        const file: any = event.target.files?.[0];
        const formData = new FormData();
        formData.append('file', file);
        await uploadFile(formData, {
            onSuccess: (data: any) => {
                if (data?.data?.id) {
                    setValue(field, data?.data?.id);
                    if (field === 'frontImageId') {
                        setFrontImagePreview(data?.data?.fullUrl);
                    }
                    if (field === 'backImageId') {
                        setBackImagePreview(data?.data?.fullUrl);
                    }
                    toast('File uploaded successfully', { type: 'success' });
                } else {
                    toast(data?.message ?? 'Error while uploading file', {
                        type: 'error',
                    });
                    if (field === 'frontImageId') {
                        fileInputRef.current.value = '';
                    }
                    if (field === 'backImageId') {
                        backfileInputRef.current.value = '';
                    }
                }
            },
            onError: (error: any) => {
                if (field === 'frontImageId') {
                    fileInputRef.current.value = '';
                }
                if (field === 'backImageId') {
                    backfileInputRef.current.value = '';
                }
                toast('Something went wrong', { type: 'error' });
            },
        });
    };

    const onSubmit = async (formData: any) => {
        const payload = {
            ...formData,
            // aadharCardNumber: Number(formData.aadharCardNumber),
            aadharCardNumber: formData.aadharCardNumber,
            userId: user?.userdetails?.id,
            status: 'Pending',
            description: '',
        };
        mutate(payload, {
            onSuccess: (data: any) => {
                if (data?.data?.success) {
                    // toast('KYC submitted successfully', { type: 'success' });
                    navigate(`/home`);
                } else {
                    toast(data?.data?.message ?? 'KYC submission failed', {
                        type: 'error',
                    });
                }
            },
            onError: (error: any) => {
                toast(
                    Array.isArray(error?.message)
                        ? error?.message[0]
                        : error?.message ?? 'Something went wrong',
                    { type: 'error' }
                );
            },
        });
    };
    return (
        <Layout>
            <div className="bg-blue-200">
                <div className="flex flex-col gap-2 w-[90%] mx-auto">
                    <p className="font-bold text-justify text-transparent from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-r bg-clip-text">
                        You need to submit a document that shows that you are
                        above 18 years of age and not a resident of Assam,
                        Odisha, Sikkim, Nagaland, Telangana, Andhra Pradesh,
                        Tamil Nadu and Karnataka.
                    </p>
                    <h1 className="text-xl">Enter details of Aadhar Card:</h1>
                    {kycData?.id && kycData?.status === 'Pending' && (
                        <p className="text-sm text-yellow-600">
                            Your KYC is under process. Please wait for some
                            time.
                        </p>
                    )}
                    {kycData?.id && kycData?.status === 'Approved' && (
                        <p className="text-sm text-green-600">
                            Your KYC has been Approved.
                        </p>
                    )}
                    {kycData?.id && kycData?.status === 'Rejected' && (
                        <>
                            <p className="text-sm text-red-600">
                                Your KYC has been Rejected.
                            </p>
                            <p>Reason: {kycData?.description ?? ''}</p>
                        </>
                    )}
                    {loading ? (
                        <div className="flex flex-col items-center justify-center gap-8 h-[300px]">
                            <Loader scale={4} />
                            <p>Please wait. Loading...</p>
                        </div>
                    ) : (
                        <form
                            className="border rounded my-[20px] mx-auto"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="p-4 mx-auto ">
                                <div>
                                    <span className="mb-8">Name *</span>
                                    <input
                                        type="text"
                                        placeholder="Enter Name"
                                        className={`border rounded p-2 w-full h-[38px] focus:outline-none ${errors.name ? 'border-red-600' : ''}`}
                                        {...register('name', {
                                            required: 'Name is required.',
                                            minLength: 2,
                                        })}
                                    />
                                    {errors.name && (
                                        <span className="text-xs text-red-600">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </div>
                                <div className="my-2">
                                    <span className="mb-8">Email Id *</span>
                                    <input
                                        type="text"
                                        placeholder="Enter Email Id"
                                        className={`border rounded p-2 w-full h-[38px] focus:outline-none ${errors.emailId ? 'border-red-600' : ''}`}
                                        {...register('emailId', {
                                            required: 'Email Id is Required.',
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@gmail\.com$/,
                                                message:
                                                    'Please enter a valid gmail id.',
                                            },
                                        })}
                                    />
                                    {errors.emailId && (
                                        <span className="text-xs text-red-600">
                                            {errors.emailId.message}
                                        </span>
                                    )}
                                </div>
                                <div className="my-2">
                                    <span className="mb-8">
                                        Date of Birth *
                                    </span>
                                    <input
                                        type="date"
                                        placeholder="Enter Date of Birth"
                                        // inputMode="numeric"
                                        className={`border rounded p-2 w-full h-[38px] focus:outline-none ${errors.dateOfBirth ? 'border-red-600' : ''}`}
                                        // maxLength={10}
                                        {...register('dateOfBirth', {
                                            required:
                                                'Date of Birth is required.',
                                        })}
                                    />
                                    {errors.dateOfBirth && (
                                        <span className="text-xs text-red-600">
                                            {errors.dateOfBirth.message}
                                        </span>
                                    )}
                                </div>
                                <div className="my-2">
                                    <span className="mb-8">
                                        Aadhar Card Number *
                                    </span>
                                    <input
                                        type="text"
                                        placeholder="Enter Aadhar Card Number"
                                        inputMode="numeric"
                                        className={`border rounded p-2 w-full h-[38px] focus:outline-none ${errors.aadharCardNumber ? 'border-red-600' : ''}`}
                                        {...register('aadharCardNumber', {
                                            required:
                                                'Aadhar Card Number is required.',
                                            minLength: {
                                                value: 12,
                                                message:
                                                    'Aadhar Card Number must be 12 digits.',
                                            },
                                            maxLength: {
                                                value: 12,
                                                message:
                                                    'Aadhar Card Number must be 12 digits.',
                                            },
                                            pattern: {
                                                value: /^\d{12}$/, // Regex to ensure only digits
                                                message:
                                                    'Aadhar Card Number must be numeric and 12 digits long.',
                                            },
                                        })}
                                    />
                                    {errors.aadharCardNumber && (
                                        <span className="text-xs text-red-600">
                                            {errors.aadharCardNumber.message}
                                        </span>
                                    )}
                                </div>
                                <div className="flex gap-2 my-4">
                                    <div>
                                        {!frontImagePreview ? (
                                            <>
                                                <div
                                                    className={`flex flex-col items-center w-full p-2 text-sm text-center bg-white rounded ${errors.frontImageId ? 'border-red-600' : ''} `}
                                                    onClick={() =>
                                                        fileInputRef.current.click()
                                                    } // Trigger file input click
                                                >
                                                    <MdUpload />
                                                    Upload Front Image *
                                                </div>
                                                <input
                                                    // name="frontImageId"
                                                    // ref={fileInputRef}
                                                    type="file"
                                                    accept="image/*"
                                                    className="hidden"
                                                    // required
                                                    {...register(
                                                        'frontImageId',
                                                        {
                                                            required:
                                                                'Front Image is required.',
                                                        }
                                                    )}
                                                    ref={(e) => {
                                                        register(
                                                            'frontImageId'
                                                        ).ref(e);
                                                        fileInputRef.current =
                                                            e;
                                                    }}
                                                    onChange={(e) =>
                                                        handleFileUpload(
                                                            e,
                                                            'frontImageId'
                                                        )
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {/* {errors.frontImageId && (
                                        <span className="text-xs text-red-600">
                                            {errors.frontImageId.message}
                                        </span>
                                    )} */}
                                        {frontImagePreview && (
                                            <div className="relative">
                                                <img
                                                    src={frontImagePreview}
                                                    alt="Front Image Preview"
                                                    className="object-cover w-32 h-32 mt-2"
                                                />
                                                <MdClose
                                                    className="absolute top-0 right-0 w-6 h-6 p-1 text-white bg-black rounded-full cursor-pointer"
                                                    onClick={() =>
                                                        setFrontImagePreview(
                                                            null
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {!backImagePreview ? (
                                            <>
                                                <div
                                                    className={`flex flex-col items-center w-full p-2 text-sm text-center bg-white rounded ${errors.backImageId ? 'border-red-600' : ''} `}
                                                    onClick={() =>
                                                        backfileInputRef.current.click()
                                                    } // Trigger file input click
                                                >
                                                    <MdUpload />
                                                    Upload Back Image *
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    className="hidden"
                                                    // required
                                                    {...register(
                                                        'backImageId',
                                                        {
                                                            required:
                                                                'Back Image is required.',
                                                        }
                                                    )}
                                                    ref={(e) => {
                                                        register(
                                                            'backImageId'
                                                        ).ref(e);
                                                        backfileInputRef.current =
                                                            e;
                                                    }}
                                                    onChange={(e) =>
                                                        handleFileUpload(
                                                            e,
                                                            'backImageId'
                                                        )
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {/* {errors.backImageId && (
                                        <span className="text-xs text-red-600">
                                            {errors.backImageId.message}
                                        </span>
                                    )} */}
                                        {backImagePreview && (
                                            <div className="relative">
                                                <img
                                                    src={backImagePreview}
                                                    alt="Front Image Preview"
                                                    className="object-cover w-32 h-32 mt-2"
                                                />
                                                <MdClose
                                                    className="absolute top-0 right-0 w-6 h-6 p-1 text-white bg-black rounded-full cursor-pointer"
                                                    onClick={() =>
                                                        setBackImagePreview(
                                                            null
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {uploading && (
                                    <div className="flex flex-col items-center justify-center gap-2">
                                        <Loader scale={2} color="blue" />
                                        <p className="text-blue-600">
                                            Uploading...
                                        </p>
                                    </div>
                                )}
                                <div className="mt-4">
                                    <button
                                        type="submit"
                                        disabled={
                                            isLoading ||
                                            kycData?.status === 'APPROVED' ||
                                            kycData?.status === 'Pending'
                                        }
                                        className={`w-full  text-[#fff] h-[38px] rounded  mt-4 ${kycData?.id && (kycData?.status === 'APPROVED' || kycData?.status === 'Pending') ? 'cursor-not-allowed bg-indigo-300' : 'bg-indigo-600'}`}
                                    >
                                        <div className="flex items-center justify-center gap-4">
                                            {isLoading
                                                ? 'Loading...'
                                                : 'Submit'}
                                            {isLoading && (
                                                <Loader
                                                    scale={2}
                                                    color="white"
                                                />
                                            )}
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default Kyc;
