import Layout from '../../components/Common/Layout/Layout';

import mainImg from '../../assets/Ludoking.jpeg';
import { useNavigate } from 'react-router-dom';

const IntoPage = () => {
    const navigate = useNavigate();

    const getYourPageLocation = () => {
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('TOKEN') as string;
            const userDetials = JSON?.parse(
                localStorage.getItem('userDetails') || '{}'
            );

            if (token && userDetials) {
                navigate('/home');
            } else {
                navigate('/Login');
            }
        }
    };

    return (
        <Layout>
            <section className=" w-full mx-auto">
                <img src={mainImg} className="w-[90%] mt-2  mx-auto" />
                <p className="text-center mx-auto text-[24px] my-8 ">
                    PLAY & WIN REAL CASH BY LUDO!
                </p>
                <button
                    className="p-4 flex justify-center items-center border gap-2 border-[#757575] mx-auto rounded mb-4  w-[90%] bg-lime-950 text-white"
                    onClick={() => getYourPageLocation()}
                >
                    Play Now
                </button>
            </section>
        </Layout>
    );
};

export default IntoPage;
