import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import React, { useState } from 'react';

const FilePreview = ({
    open,
    onClose,
    fileUrl,
}: {
    open: boolean;
    onClose: () => void;
    fileUrl: string;
}) => {
    const [previewUrl, setPreviewUrl] = useState('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    return (
        <div>
            <Dialog
                open={open}
                as="div"
                className="relative z-10 focus:outline-none"
                onClose={onClose}
            >
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4">
                        <DialogPanel
                            // transition
                            className="w-full max-w-md rounded-xl p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <DialogTitle
                                as="h3"
                                className="py-2 font-medium text-center"
                            >
                                Your Profile Image
                            </DialogTitle>
                            <img src={fileUrl} />
                            <div className="mt-4">
                                <Button
                                    className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                                    onClick={onClose}
                                >
                                    Close
                                </Button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default FilePreview;
