import { useState } from 'react';
import Layout from '../Common/Layout/Layout';
import { toast } from 'react-toastify';
import useCreateAccountDetails from '../../apis/hooks/useWithdrawalPostDetails';
import { useUserData } from '../../Context/UserContext';
import Loader from '../Common/Loader/Loader';

const WithdrawalUPI = ({ data }: { data?: any }) => {
    const { user } = useUserData();
    const [accountName, setAccountName] = useState(data?.accountName ?? '');
    const [upiId, setUpiId] = useState(data?.upiId ?? '');
    const { mutate: submitUPI, isLoading } = useCreateAccountDetails();

    const submitData = () => {
        const data = {
            primaryChoice: 'UPI',
            upiId: upiId,
            // amount:amount,
            accountName: accountName,
            userId: user?.userdetails?.id,
        };

        submitUPI(
            data,
            {
                onSuccess: (res: any) => {
                    if (res?.data?.error) {
                        toast(res?.message ?? 'Details not Submitted', {
                            type: 'error',
                        });
                    } else if (res?.data?.message) {
                        toast(res?.data?.message ?? 'Details Submited !', {
                            type: 'success',
                        });
                    } else {
                    }
                },
            },
            {
                onError: (error: any) => {
                    toast(error?.message ?? 'try again !', {
                        type: 'error',
                    });
                },
            }
        );
    };

    return (
        <>
            <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                    <span>Account Holder Name</span>
                    <input
                        type="text"
                        placeholder="Account Name"
                        value={accountName}
                        className="w-full border rounded h-[38px] focus:outline-none px-2"
                        onChange={(e) => setAccountName(e.target.value)}
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <span>UPI ID</span>
                    <input
                        type="text"
                        placeholder="Enter UPI Id"
                        value={upiId}
                        className="w-full border rounded h-[38px] focus:outline-none px-2"
                        onChange={(e) => setUpiId(e.target.value?.trim())}
                    />
                </div>
                <button
                    disabled={!accountName || !upiId || isLoading}
                    className={`p-2 flex justify-center items-center border gap-2 border-[#757575] mx-auto rounded mb-4 w-full text-white ${!accountName || !upiId ? 'bg-slate-400' : 'bg-green-700'}`}
                    onClick={() => submitData()}
                >
                    {isLoading ? 'Loading...' : 'Submit'}
                    {isLoading && <Loader scale={2} color="white" />}
                </button>
            </div>
        </>
    );
};

export default WithdrawalUPI;
