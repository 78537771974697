import { useMutation } from '@tanstack/react-query';
import { Client } from '..';
import { API_PATHS } from '../constants';

type Props = {
    data: any;
    isLoading: boolean;
    mutate: any;
    error: any;
};

export const useGetWalletLogs = (): Props => {
    const { data, isLoading, mutate, error } = useMutation(
        ['get-user-wallet-logs'],
        (data: any) => {
            return Client.post(API_PATHS.GET_USER_WALLET_LOGS, data);
        }
    );

    return {
        data: data,
        isLoading,
        mutate,
        error,
    };
};

export const useAddWallet = (): Props => {
    const { data, isLoading, mutate, error } = useMutation(
        ['add-wallet'],
        (data: any) => {
            return Client.post(API_PATHS.ADD_WALLET, data);
        }
    );

    return {
        data: data,
        isLoading,
        mutate,
        error,
    };
};
