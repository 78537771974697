import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getAccessToken = () => {
    return localStorage.getItem('token') || localStorage.getItem('TOKEN');
};

axios.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        // handle the error
        return Promise.reject(error?.response || error);
    }
);

const getInstance = ({ headers: _headers = {} }: { headers?: any }) => {
    const headers: any = {
        'client-type': 'ADMIN',
        ..._headers,
    };
    const tokenContext = getAccessToken() ? { token: getAccessToken() } : null;
    if (tokenContext) {
        headers['Authorization'] = `Bearer ${tokenContext.token}`;
    }
    const Instance = axios.create({
        baseURL: BASE_URL,
        timeout: 50000,
        headers: headers,
    });
    Instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject(
                error?.response?.data?.error || error?.response
            );
        }
    );
    return Instance;
};

interface Config {
    params?: any;
    headers?: any;
}

export const Client: any = {
    get: (endpoint: string, config: Config = {}) => {
        let params: any = [];
        if (config.params) {
            for (let p in config.params) {
                let key = p;
                if (Array.isArray(config.params[p])) {
                    config.params[p].forEach((element: any) => {
                        params.push(`${key}=${encodeURIComponent(element)}`);
                    });
                } else {
                    params.push(
                        `${key}=${encodeURIComponent(config.params[p])}`
                    );
                }
            }
        }
        const Instance = getInstance({ headers: config.headers || {} });
        return Instance(endpoint, {
            params: config.params || {},
        });
    },
    post: (endpoint: string, data: any, config: Config = {}) => {
        let params: any = [];
        if (config.params) {
            for (let p in config.params) {
                let key = p;
                if (Array.isArray(config.params[p])) {
                    config.params[p].forEach((element: any) => {
                        params.push(`${key}=${encodeURIComponent(element)}`);
                    });
                } else {
                    params.push(
                        `${key}=${encodeURIComponent(config.params[p])}`
                    );
                }
            }
            endpoint = endpoint + (params.length ? '?' + params.join('&') : '');
        }
        const Instance = getInstance({ headers: config.headers || {} });
        return Instance.post(endpoint, data);
    },
    delete: (endpoint: string, data: any, config: Config = {}) => {
        let params: any = [];
        if (config.params) {
            for (let p in config.params) {
                let key = p;
                if (Array.isArray(config.params[p])) {
                    config.params[p].forEach((element: any) => {
                        params.push(`${key}=${encodeURIComponent(element)}`);
                    });
                } else {
                    params.push(
                        `${key}=${encodeURIComponent(config.params[p])}`
                    );
                }
            }
            endpoint = endpoint + (params.length ? '?' + params.join('&') : '');
        }
        const Instance = getInstance({ headers: config.headers || {} });
        return Instance.delete(endpoint, data);
    },

    put: (endpoint: string, data: any, config: Config = {}) => {
        let params: any = [];
        if (config.params) {
            for (let p in config.params) {
                let key = p;
                if (Array.isArray(config.params[p])) {
                    config.params[p].forEach((element: any) => {
                        params.push(`${key}=${encodeURIComponent(element)}`);
                    });
                } else {
                    params.push(
                        `${key}=${encodeURIComponent(config.params[p])}`
                    );
                }
            }
            endpoint = endpoint + (params.length ? '?' + params.join('&') : '');
        }
        const Instance = getInstance({ headers: config.headers || {} });
        return Instance.put(endpoint, data);
    },

    patch: (endpoint: string, data: any, config: Config = {}) => {
        let params: any = [];
        if (config.params) {
            for (let p in config.params) {
                let key = p;
                if (Array.isArray(config.params[p])) {
                    config.params[p].forEach((element: any) => {
                        params.push(`${key}=${encodeURIComponent(element)}`);
                    });
                } else {
                    params.push(
                        `${key}=${encodeURIComponent(config.params[p])}`
                    );
                }
            }
            endpoint = endpoint + (params.length ? '?' + params.join('&') : '');
        }
        const Instance = getInstance({ headers: config.headers || {} });
        return Instance.patch(endpoint, data);
    },
};
