import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Common/Layout/Layout';
import AlertSection from '../../components/Common/AlertSection/AlertSection';
import PlayerHeader from '../../components/Common/PlayerHeader';
import UploadImg from '../../assets/UploadImg';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { TiDeleteOutline } from 'react-icons/ti';
import { MdContentCopy } from 'react-icons/md';
import { useUserData } from '../../Context/UserContext';
import { useUploadFiles } from '../../apis/hooks/useUploadFiles';
import Loader from '../../components/Common/Loader/Loader';
import { useAddWallet } from '../../apis/hooks/useWallet';

const PrePayment = () => {
    const navigate = useNavigate();
    const fileInputRef = useRef<any>(null);

    const [enterAmount, setEnterAmount] = useState('');
    const [utr_number, setUtr_number] = useState('');
    const [fileData, setFileData] = useState<any>(null);
    const [upiData, setUpiData] = useState<any>(null);

    const { user } = useUserData();

    const { mutate: uploadFile, isLoading: uploading } = useUploadFiles();
    const { mutate: addWallet, isLoading: walletSubmitting } = useAddWallet();

    const handleFileUpload = async (e: any) => {
        const fileToUpload = e.target.files[0];

        const formData = new FormData();
        formData.append('file', fileToUpload);

        await uploadFile(formData, {
            onSuccess: (data: any) => {
                if (data?.data?.id) {
                    setFileData(data?.data);
                } else {
                    toast(data?.message ?? 'Error while uploading file', {
                        type: 'error',
                    });
                    fileInputRef.current.value = '';
                }
            },
            onError: (error: any) => {
                fileInputRef.current.value = '';
                toast('Something went wrong', { type: 'error' });
            },
        });
    };
    const onADD = async () => {
        if (enterAmount && utr_number && fileData?.id) {
            const payload = {
                userId: user?.userdetails?.id,
                amount: parseInt(enterAmount),
                type: 'CREDIT',
                fileId: fileData?.id,
                utr_number: parseInt(utr_number),
            };

            addWallet(payload, {
                onSuccess: (data: any) => {
                    if (data?.data?.status === 'success') {
                        toast(
                            data?.data?.message ?? 'Wallet added successfully',
                            {
                                type: 'success',
                            }
                        );
                        navigate('/home');
                    }
                },
                onError: (error: any) => {
                    console.log('error', error);

                    toast(error?.message ?? 'Something went wrong', {
                        type: 'error',
                    });
                },
            });
        } else {
            toast('Please fill the details');
        }
    };
    const paymentDetails = async () => {
        try {
            const response = await fetch(
                'https://starfish-app-cnmgd.ondigitalocean.app/api/upi-payment',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const data = await response.json();

            if (data?.error) {
                toast(data?.message);
            } else {
                data.map((res: any) => {
                    if (res.active) {
                        setUpiData(res);
                    }
                });
            }
        } catch (error) {
            toast('Something went wrong', { type: 'error' });
        }
    };

    useEffect(() => {
        const amount = JSON.parse(localStorage.getItem('enteredAmount') || '');
        setEnterAmount(amount);
        paymentDetails();
    }, []);

    return (
        <Layout>
            <section className="w-full mt-2">
                <div className="w-[90%] mx-auto">
                    <span className="text-[#0d6efd] text-[12px] font-bold pb-2 border-b-[5px] border-[#F8F8F8] flex items-center justify-center w-full text-center">
                        Pay Through UPI ID
                    </span>
                    <div className="flex flex-col gap-2 p-1 mb-2">
                        <AlertSection
                            className="p-2"
                            message={
                                'अगर कोई यूजर पैसा add करते टाइम किसी भी तरह कि फ्रॉड बाजी करता है तो, या फिर बार बार गलत Screen shot (UTR Number) डालता है तो उसकी ID block कर दी जाएगी'
                            }
                            alertType="INFO"
                        />
                        <AlertSection
                            className="p-2 bg-yellow-300"
                            message={
                                'यूजर Deposit करते समय ध्यान दे जो प्रजेंट में UPI ID लगी हुई है इस पर पेमेंट करें अन्यथा अपडेट नहीं होगी !'
                            }
                            alertType="WARNING"
                        />
                        <AlertSection
                            className="p-2"
                            message={
                                "अगर किसी यूज़र को 3 हजार RS से जायदा add करने हो या फ़िर लगे हुए (QR Code - UPI  I'd) में नही डाल पा रहा है तो वो Whatsapp पर मैसेज कर के add करा सकता है,"
                            }
                            alertType="INFO"
                        />
                    </div>
                </div>
                {/* <div className="p-4 flex justify-center items-center border gap-2 border-[#757575] rounded mb-4 m w-[90%] mx-auto bg-slate-300">
                    <img
                        src={`https://gotkingimages.s3.ap-southeast-2.amazonaws.com/images/upi.webp`}
                        className="w-10"
                    />
                    <span>
                        Bank की समस्या के कारण UPI withdrawal बंद हैं असुविधा के
                        लिए खेद है जल्द ही दुबारा चालू हो जाएगा तब तक आप बैंक से
                        Withdrawal ले सकते हैं।। Notice:- ! | 🙏
                    </span>
                </div> */}
                <div
                    className=" cursor-pointer p-4 flex justify-center items-center border gap-2 border-[#757575] mx-auto rounded mb-4  w-[90%] bg-lime-950 text-white"
                    onClick={() => {
                        navigator.clipboard.writeText(upiData?.upiId);
                        toast('Code Copied', {
                            type: 'success',
                        });
                    }}
                >
                    <img
                        src={`https://gotkingimages.s3.ap-southeast-2.amazonaws.com/images/upi.webp`}
                        className="w-10"
                    />
                    <p className="flex items-center gap-2">
                        {upiData?.upiId} <MdContentCopy />
                    </p>
                </div>
                <div className="p-4">
                    <img src={upiData?.fileUrl} />
                </div>

                <div className="p-[10px] w-[90%] mx-auto">
                    <span>Enter Amount</span>
                    <div className="flex my-4 border rounded">
                        <span className="bg-[#F8F9FA] w-[42px] border-r text-center py-2">
                            ₹
                        </span>
                        <input
                            type="tel"
                            placeholder="Amount"
                            value={enterAmount}
                            className="w-full h-[38px] focus:outline-none ml-2"
                            minLength={2}
                            maxLength={5}
                            onChange={(e) =>
                                setEnterAmount(e.target.value?.trim())
                            }
                        />
                    </div>
                    <span>Enter UTR Number</span>
                    <div className="flex my-4 border rounded">
                        <span className="bg-[#F8F9FA] w-[42px] border-r text-center py-2">
                            ₹
                        </span>
                        <input
                            type="tel"
                            placeholder="Enter UTR Number"
                            value={utr_number}
                            className="w-full h-[38px] focus:outline-none ml-2"
                            minLength={12}
                            maxLength={12}
                            onChange={(e) =>
                                setUtr_number(e.target.value?.trim())
                            }
                        />
                    </div>
                </div>
                {!fileData ? (
                    <>
                        <div
                            className="p-4 flex justify-center items-center border gap-2 border-[#757575] rounded w-[90%] mx-auto bg-slate-300"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <UploadImg />
                            <div className="flex gap-2">
                                {uploading ? (
                                    <div className="flex gap-4">
                                        <p>Uploading...</p>
                                        <Loader scale={2.7} />
                                    </div>
                                ) : (
                                    'Upload your Screen Shot'
                                )}
                            </div>
                        </div>
                        <input
                            ref={fileInputRef}
                            className="hidden"
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileUpload(e)}
                            required
                            data-max-size="4096000"
                            id="upload_ipt"
                        />
                    </>
                ) : (
                    <div className="w-[90%] mx-auto">
                        <div className="relative flex justify-center p-2">
                            <img src={fileData?.fullUrl} width={300} />
                            <TiDeleteOutline
                                className="absolute top-[-20px] cursor-pointer right-2"
                                size={28}
                                color="red"
                                onClick={() => setFileData(null)}
                            />
                        </div>
                    </div>
                )}

                <button
                    disabled={walletSubmitting}
                    className={`p-2 flex justify-center items-center border gap-2 border-[#757575] mx-auto rounded my-4  w-[90%]  text-white ${!enterAmount || !utr_number || !fileData?.id ? 'bg-slate-400' : 'bg-green-700'}`}
                    onClick={() => onADD()}
                >
                    <div className="flex items-center justify-center gap-4">
                        {walletSubmitting ? 'Submitting...' : 'Submit'}
                        {walletSubmitting && <Loader scale={2} color="white" />}
                    </div>
                </button>
            </section>
        </Layout>
    );
};

export default PrePayment;
