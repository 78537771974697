import { useState } from 'react';
import Layout from '../Common/Layout/Layout';
import useWithdrawalPostDetails from '../../apis/hooks/useWithdrawalPostDetails';
import { toast } from 'react-toastify';
import useWithdrawalDetailsData from '../../apis/hooks/useWithdrawalDetailsData';
import bank from '../../assets/Blank.png';
import { useForm } from 'react-hook-form';
import { useUserData } from '../../Context/UserContext';
import Loader from '../Common/Loader/Loader';

const WithdrawalBank = ({ data }: { data?: any }) => {
    const { user } = useUserData();
    const [accountName, setAccountName] = useState(data?.accountName ?? '');
    const [accountNumber, setAccountNumber] = useState(
        data?.accountNumber ?? ''
    );
    const [confirmAccountNumber, setConfirmAccountNumber] = useState(
        data?.accountNumber ?? ''
    );
    const [bankName, setBankName] = useState(data?.bank ?? '');
    const [IFSC, setIFSC] = useState(data?.IFSCCode ?? '');
    const { mutate, isLoading } = useWithdrawalPostDetails();

    // const { register, handleSubmit, formState: { errors } } =useForm({
    // 	mode: 'onSubmit',
    // 	defaultValues: {
    // 		primaryChoice: 'Bank',
    // 		amount: amount,
    // 		holderName: holderName,
    // 		ifsc: IFSC,
    // 		bankName: bankName,
    // 		accountNumber: accountNumber,
    // 	}
    // })

    const submitData = () => {
        const data = {
            userId: user?.userdetails?.id,
            primaryChoice: 'BANK',
            accountName: accountName,
            IFSCCode: IFSC,
            bank: bankName,
            accountNumber: +accountNumber,
        };

        mutate(data, {
            onSuccess: (res: any) => {
                if (res?.data?.error) {
                    toast(res?.message ?? 'Details not Submitted', {
                        type: 'error',
                    });
                } else if (res?.data?.message) {
                    toast(res?.data?.message ?? 'Details Submited !', {
                        type: 'success',
                    });
                } else {
                }
            },
            onError: (error: any) => {
                toast(error?.message ?? 'try again !', {
                    type: 'error',
                });
            },
        });
    };
    return (
        <>
            <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                    <span>Account Holder Name</span>
                    <input
                        type="text"
                        placeholder="Account Name"
                        value={accountName}
                        className="w-full h-[38px] focus:outline-none px-2 border rounded"
                        onChange={(e) => setAccountName(e.target.value?.trim())}
                    />
                </div>

                <div className="flex flex-col gap-1">
                    <span>Account Number</span>
                    <input
                        type="password"
                        inputMode="numeric"
                        placeholder="Account Number"
                        value={accountNumber}
                        className="w-full h-[38px] focus:outline-none px-2 border rounded"
                        onChange={(e) =>
                            setAccountNumber(e.target.value?.trim())
                        }
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <span>Confirm Account Number</span>
                    <input
                        type="text"
                        inputMode="numeric"
                        placeholder="Confirm Account Number"
                        value={confirmAccountNumber}
                        className="w-full h-[38px] focus:outline-none px-2 border rounded"
                        onChange={(e) =>
                            setConfirmAccountNumber(e.target.value?.trim())
                        }
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <span>Bank Name</span>
                    <input
                        type="text"
                        placeholder="Bank Name"
                        value={bankName}
                        className="w-full h-[38px] focus:outline-none px-2 border rounded"
                        onChange={(e) => setBankName(e.target.value?.trim())}
                    />
                </div>

                <div className="flex flex-col gap-1">
                    <span>IFSC Code</span>
                    <input
                        type="text"
                        placeholder="IFSC Code"
                        value={IFSC}
                        className="w-full h-[38px] focus:outline-none px-2 border rounded"
                        onChange={(e) => setIFSC(e.target.value?.trim())}
                    />
                </div>
                <button
                    disabled={
                        !accountName ||
                        !confirmAccountNumber ||
                        !accountNumber ||
                        !IFSC ||
                        isLoading
                    }
                    className={`p-2 flex justify-center items-center border gap-2 border-[#757575] mx-auto rounded mb-4  w-full  text-white ${!accountName || !confirmAccountNumber || !accountNumber || !IFSC ? 'bg-slate-400' : 'bg-green-700'}`}
                    onClick={() => submitData()}
                >
                    {isLoading ? 'Loading...' : 'Submit'}
                    {isLoading && <Loader scale={2} color="white" />}
                </button>
            </div>
        </>
    );
};

export default WithdrawalBank;
