import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import { toast } from 'react-toastify';
import WhatsappIcon from '../../assets/WhatsappIcon';
import Telegram from '../../assets/Telegram';
import { UserContext, useUserData } from '../../Context/UserContext';
const buttonType = [
    {
        key: 'Whatsapp',
        type: 'Share to Whatsapp',
    },
    {
        key: 'WhatsappBusiness',
        type: 'Share to Business Whatsapp',
    },
    {
        key: 'Telegram',
        type: 'Share to Telegram',
    },
    {
        key: 'Copy',
        type: 'Copy to clipboard',
    },
];

const ReferAndEarn = () => {
    const { user } = useUserData();

    const handleResponse = async (type: string) => {
        // const referCode = 'YOUR_REFER_CODE'; // Replace with your actual refer code
        // const message = `Check out this website and use my refer code: ${referCode}`;
        // const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
        const referCode = user?.userdetails?.referCode ?? '';
        const mkgPlayers = `https://mkgplayers.com/register/${referCode}`;
        const message = `Check out this amazing website ${mkgPlayers} and use my refer code: ${referCode}`;
        const imageUrl =
            'https://khelo-players.blr1.digitaloceanspaces.com/uploads/lijb21a4k0pyahdj8vq04ehu7.png';
        const helperMessage = `MkgPlayers पर Ludo खेल कर या फ़िर दोस्तो को शेयर करने पे आपको मिलेगा 2% Refer Life Time के लिए, तो फ़िर देर किस बात की तुरंत अपने दोस्तो को शेयर करिए,
          (( 24 घण्टे Support ))`;
        // const encodedMessage = encodeURIComponent(
        //     `${message}\n${imageUrl}\n${helperMessage}`
        // );
        const encodedMessage = encodeURIComponent(
            `${message}\n${helperMessage}`
        );
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;
        const whatsappBusinessUrl = `https://wa.me/?text=${encodedMessage}`;
        switch (type) {
            case 'Whatsapp':
                return window.open(whatsappUrl);
            case 'Telegram':
                return window.open('https://telegram.org/');
            case 'Copy':
                return clickCopy();
            case 'WhatsappBusiness':
                return window.open(whatsappBusinessUrl);
            default:
                break;
        }
    };

    const clickCopy = () => {
        if (user?.userdetails?.referCode) {
            navigator.clipboard.writeText(user?.userdetails?.referCode);
            toast('Code Copied', {
                type: 'success',
            });
        }
    };

    return (
        <Layout>
            <section className="w-[90%] mx-auto flex flex-col gap-4">
                <div className="text-sm border rounded-lg shadow-lg">
                    <span className="flex justify-center w-full bg-[#F8F9FA] py-2 px-4  text-center border-b">
                        Your Referral Earnings
                    </span>
                    <div className="grid grid-cols-2 p-4">
                        <div className="w-full text-center">
                            <p className="text-center ">Referred Players</p>
                            <p className="text-center ">
                                {user?.userdetails?.referralUserCount ?? 0}
                            </p>
                        </div>

                        <div className="w-full text-center border-l">
                            <p className="text-center ">Referral Earning</p>
                            <p className="text-center ">
                                ₹{user?.userdetails?.earnFromReferral ?? 0}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="border rounded-lg shadow-lg ">
                    <span className="flex justify-center w-full bg-[#F8F9FA] p-2  text-center border-b">
                        Referral Code : &nbsp;
                        <span className="font-semibold">
                            {user?.userdetails?.referCode ?? ''}
                        </span>
                    </span>
                    <div className="flex flex-col items-center">
                        <img
                            src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/illustrations/refer.webp"
                            height={300}
                            width={300}
                        />

                        {/* <input
                            type="text"
                            value={user?.referCode ?? ''}
                            disabled
                            className="font-bold text-center w-full h-[38px] focus:outline-none border rounded pl-4 "
                        /> */}
                        <span className="flex justify-center text-[22px] font-extrabold mx-auto">
                            OR
                        </span>
                        {buttonType.map((item: any, index: number) => {
                            return (
                                <div
                                    className="flex flex-col w-full gap-2 p-2"
                                    key={index}
                                >
                                    <button
                                        className={` ${item?.key === 'Whatsapp' ? 'bg-green-800 hover:bg-green-300 ' : item?.key === 'Telegram' ? 'bg-[#0d6efd] hover:bg-[#a1bce6]' : 'bg-[#6c757d] hover:bg-slate-400'} flex p-2 justify-center items-center gap-2 text-[#fff] rounded-lg `}
                                        onClick={() =>
                                            handleResponse(item?.key)
                                        }
                                    >
                                        {item?.key === 'Whatsapp' ? (
                                            <WhatsappIcon className="w-[24px] h-[24px]" />
                                        ) : item?.key === 'WhatsappBusiness' ? (
                                            <WhatsappIcon className="w-[24px] h-[24px]" />
                                        ) : (
                                            item?.key === 'Telegram' && (
                                                <Telegram />
                                            )
                                        )}

                                        {item?.type}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="border rounded-lg shadow-lg ">
                    <span className="flex justify-center w-full bg-[#F8F9FA] py-2 px-4  text-center border-b mb-4">
                        How It Works
                    </span>

                    <div className="text-center border rounded-lg w-[95%] mx-auto p-2 mb-4 ">
                        <p className="">
                            You can refer and <b>Earn 2%</b> of your referral
                            winning, every time
                        </p>
                        <p className="pt-2 mt-2 border-t ">
                            Like if your player plays for <b>₹10000</b> and
                            wins, You will get{' '}
                            <b className="text-green-800">₹200</b> as referral
                            amount.
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default ReferAndEarn;
