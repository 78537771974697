import { startTransition, useEffect, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import UploadImgDialog from '../../components/UploadImgDialog/UploadImgDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useGetBattle, useShareRoomCode } from '../../apis/hooks/useBattles';
import { Tooltip } from 'react-tooltip';
import User from '../../assets/UserProfile';

import vsImg from '../../assets/VS.webp';
import { io, Socket } from 'socket.io-client';
import { useUserData } from '../../Context/UserContext';
import Loader from '../../components/Common/Loader/Loader';
import { IoArrowBack } from 'react-icons/io5';
import GameRules from '../../components/GameRules/GameRules';
import { useSocket } from '../../Context/SocketContext';

const Room = () => {
    const navigate = useNavigate();
    const { message, socket } = useSocket();

    const { id: battle_id } = useParams<{ id: string }>();
    const [roomCode, setRoomCode] = useState('');
    const [open, setIsOpen] = useState(false);
    const [status, setStatus] = useState('');
    const { user } = useUserData();
    const {
        isLoading,
        data: battleDetails,
        refetch,
    } = useGetBattle({
        id: battle_id ?? '',
    });
    const { mutate: sendRoomCode } = useShareRoomCode({
        id: battle_id ?? '',
    });

    const [showGameRules, setShowGameRules] = useState<boolean>(false);

    // refreshing componnet, will remove, after scoket issue is fixed
    const [refresh, setRefresh] = useState<number>(0); // State to trigger re-render
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         // console.log('Interval triggered');
    //         setRefresh((prev) => prev + 1); // Update the state to trigger re-render
    //     }, 5000); // 5 seconds

    //     // Clean up the interval on component unmount
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);

    // useEffect(() => {
    //     refetch();
    // }, [refresh]);

    useEffect(() => {
        if (message?.data?.data?.id) {
            refetch();
        }
    }, [message]);

    const onSubmitCode = async () => {
        if (!roomCode) {
            toast('Please enter room code', { type: 'error' });
            return;
        }

        if (roomCode?.trim()?.length !== 8) {
            toast('RoomCode must contain 8 digits', { type: 'error' });
            return;
        }

        const payload = {
            battleId: battle_id,
            roomCode: roomCode,
        };

        sendRoomCode(payload, {
            onSuccess: (res: any) => {
                if (res?.data?.data?.roomCode) {
                    battleDetails.roomCode = res?.data?.data?.roomCode;
                    toast(res?.data?.message ?? 'Room code sent successfully', {
                        type: 'success',
                    });
                    // socket.emit('newMessage', {
                    //     message: 'Room Code Send Successfully',
                    //     data: res?.data?.data,
                    // });
                } else {
                    toast(res?.data?.message ?? 'Failed to send room code', {
                        type: 'error',
                    });
                }
            },
            onError: (error: any) => {
                toast(error?.response?.data?.message, { type: 'error' });
            },
        });
    };

    const handleResponse = (status: string) => {
        switch (status) {
            case 'Won':
                setStatus('Won');
                setIsOpen(true);
                break;
            case 'Lost':
                setStatus('Lost');
                setIsOpen(true);

                break;
            case 'Cancel':
                setStatus('Cancel');
                setIsOpen(true);

                break;
            default:
                return null;
        }
    };

    const openLudoGame = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android/.test(userAgent);

        if (isMobile) {
            // Mobile device
            const isIOS = /iphone|ipad|ipod/.test(userAgent);
            const isAndroid = /android/.test(userAgent);

            if (isIOS) {
                window.open(
                    'https://apps.apple.com/app/ludo-king/id1631780337',
                    '_blank'
                );
                // window.location.href =
                //     'https://play.google.com/store/apps/details?id=com.ludo.king'; // Replace with your iOS app deep link
            } else if (isAndroid) {
                window.open(
                    'https://apps.apple.com/app/ludo-king/id1631780337',
                    '_blank'
                );

                // window.location.href =
                //     'https://play.google.com/store/apps/details?id=com.ludo.king'; // Replace with your Android app deep link
            } else {
                window.open(
                    'https://apps.apple.com/app/ludo-king/id1631780337',
                    '_blank'
                );

                // Default behavior if not specifically iOS or Android (handle edge cases)
                // window.location.href =
                //     'https://play.google.com/store/apps/details?id=com.ludo.king';
            }
        } else {
            window.open(
                'https://apps.apple.com/app/ludo-king/id1631780337',
                '_blank'
            );

            // Desktop or non-standard mobile browser behavior
            // window.location.href =
            //     'https://play.google.com/store/apps/details?id=com.ludo.king';
        }
    };

    const renderGameResults = () => (
        <div className="flex flex-col justify-center items-center gap-2 border rounded mb-2 w-[90%] mx-auto pb-4 shadow-md">
            <span className="w-full bg-[#F8F9FA] py-2 text-center border-b ">
                Game Results
            </span>

            {battleDetails?.status === 'Cancelled' ? (
                <div className="text-center gap-2 mt-4 w-[90%] mx-4 text-[red]">
                    This battle is Cancelled
                </div>
            ) : (battleDetails?.challengerResponse &&
                  battleDetails?.challengerId === user?.userdetails?.id) ||
              (battleDetails?.acceptorResponse &&
                  battleDetails?.acceptorId === user?.userdetails?.id) ? (
                renderUserResponse()
            ) : (
                renderPostGameActions()
            )}
        </div>
    );

    const renderWonResponse = (name: string) => {
        const responseFileData = battleDetails?.[`${name}FileData`];

        return (
            <>
                <p>
                    Status :{' '}
                    <span className="text-green-600">
                        {battleDetails?.[name]}
                    </span>
                </p>
                <p>Please Wait for final Results</p>
                <img
                    className="p-2"
                    src={responseFileData?.fullUrl ?? ''}
                    alt="status image"
                />
            </>
        );
    };

    const renderLostResponse = (name: string) => {
        return (
            <>
                <p>
                    Status :{' '}
                    <span className="text-red-600">
                        {battleDetails?.[name]}
                    </span>
                </p>
                <p>Please Wait for final Results</p>
            </>
        );
    };

    const renderCancelResponse = (name: string) => {
        return (
            <>
                <p>
                    Status :{' '}
                    <span className="text-red-600">
                        {battleDetails?.[name]}
                    </span>
                </p>
                <div>
                    {battleDetails?.description?.map(
                        (item: any, index: number) => (
                            <p key={index}>{item?.description}</p>
                        )
                    )}
                </div>
                <p>Please Wait for final Results</p>
            </>
        );
    };

    const renderUserResponse = () => (
        <>
            <div className="flex flex-col items-center justify-between gap-2">
                Your Response
                {battleDetails?.challengerResponse === 'Won' &&
                    battleDetails?.challengerId === user?.userdetails?.id && (
                        <>{renderWonResponse('challengerResponse')}</>
                    )}
                {battleDetails?.acceptorResponse === 'Won' &&
                    battleDetails?.acceptorId === user?.userdetails?.id && (
                        <>{renderWonResponse('acceptorResponse')}</>
                    )}
                {battleDetails?.challengerResponse === 'Lost' &&
                    battleDetails?.challengerId === user?.userdetails?.id && (
                        <>{renderLostResponse('challengerResponse')}</>
                    )}
                {battleDetails?.acceptorResponse === 'Lost' &&
                    battleDetails?.acceptorId === user?.userdetails?.id && (
                        <>{renderLostResponse('acceptorResponse')}</>
                    )}
                {battleDetails?.challengerResponse === 'Cancelled' &&
                    battleDetails?.challengerId === user?.userdetails?.id && (
                        <>{renderCancelResponse('challengerResponse')}</>
                    )}
                {battleDetails?.acceptorResponse === 'Cancelled' &&
                    battleDetails?.acceptorId === user?.userdetails?.id && (
                        <>{renderCancelResponse('acceptorResponse')}</>
                    )}
            </div>
        </>
    );

    const renderPostGameActions = () => (
        <>
            <div className="flex justify-between gap-2 mt-4 w-[90%] mx-4">
                After completion of your game, select the status of the game and
                post your screenshot below
            </div>
            <div className='flex flex-col items-center w-full gap-4'>
                <button
                    className={`w-[90%] rounded h-[42px] ${
                        battleDetails?.roomCode
                            ? 'bg-green-800 text-[#fff]'
                            : 'bg-gray-400 cursor-not-allowed opacity-60'
                    }`}
                    onClick={() => handleResponse('Won')}
                    disabled={!battleDetails?.roomCode}
                >
                    I Won
                </button>
                <button
                    className={`w-[90%] rounded h-[42px] ${
                        battleDetails?.roomCode
                            ? 'bg-red-800 text-[#fff]'
                            : 'bg-gray-400 cursor-not-allowed opacity-60'
                    }`}
                    onClick={() => handleResponse('Lost')}
                    disabled={!battleDetails?.roomCode}
                >
                    I Lost
                </button>
                <button
                    className="border-2 border-black w-[90%] rounded h-[42px]"
                    onClick={() => handleResponse('Cancel')}
                >
                    Cancel
                </button>
            </div>
        </>
    );

    const renderFinalResults = () => (
        <div className="flex flex-col justify-center items-center gap-2 border rounded mb-2 w-[90%] mx-auto pb-4 shadow-md">
            <span className="w-full bg-[#F8F9FA] py-2 px-4 text-center border-b ">
                Final Results
            </span>
            <div
                className={`flex justify-center gap-2 text-[22px] mt-4 w-[90%] mx-4 ${
                    battleDetails?.winnerId === user?.userdetails?.id
                        ? 'bg-gradient-to-r from-pink-600 via-orange-500 to-red-500 inline-block text-transparent bg-clip-text'
                        : 'bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text'
                }`}
            >
                {battleDetails?.winnerId === user?.userdetails?.id
                    ? 'Congratulations! You Won! the battle'
                    : 'Well Played!'}
            </div>
        </div>
    );

    return (
        <Layout>
            {isLoading ? (
                <div className="flex flex-col gap-8 items-center justify-center h-[300px]">
                    <Loader scale={4} />
                    <p>Please Wait. Loading...</p>
                </div>
            ) : !battleDetails?.id ? (
                <div className="mx-auto font-bold text-center text-red-500">
                    Unable to find room. Please try again.
                </div>
            ) : (
                <>
                    <div className="w-[90%] mx-auto flex justify-between pt-2 pb-4">
                        <button
                            className=" cursor-pointer flex gap-1 text-[#0d6efd] hover:text-white hover:bg-[#0d6efd]  border border-[#0d6efd] items-center rounded-md leading-loose px-2"
                            onClick={() => navigate(-1)}
                        >
                            <IoArrowBack />
                            Back
                        </button>
                        <button
                            className=" cursor-pointer flex gap-1 text-[#0d6efd] hover:text-white hover:bg-[#0d6efd]   border border-[#0d6efd] items-center px-2 rounded-md leading-loose"
                            onClick={() => setShowGameRules(true)}
                        >
                            Game Rules
                        </button>
                    </div>
                    <div className="mb-6 transition ease-in-out transform w-[90%] mx-auto shadow-md">
                        <div className="flex justify-between p-4 border rounded-md">
                            <div
                                className="flex gap-2 my-auto cursor-pointer"
                                data-tooltip-id="challengerUsername"
                                data-tooltip-content={
                                    battleDetails?.challengerUsername
                                }
                            >
                                <User className="w-[24px] h-[24px] rounded-xl border" />
                                <span>
                                    {battleDetails?.challengerUsername?.slice(
                                        0,
                                        12
                                    ) + '...'}
                                </span>
                            </div>
                            <div className="">
                                <img
                                    src={vsImg}
                                    className="w-[30px] h-10 mx-auto"
                                />
                                <span className="font-bold text-lime-600">
                                    Rs {battleDetails?.amount}
                                </span>
                            </div>
                            <div
                                className="flex gap-2 my-auto cursor-pointer"
                                data-tooltip-id="acceptorUsername"
                                data-tooltip-content={
                                    battleDetails?.acceptorUsername
                                }
                            >
                                <span>
                                    {battleDetails?.acceptorUsername
                                        ? battleDetails?.acceptorUsername?.slice(
                                              0,
                                              12
                                          )
                                        : '' + '...'}
                                </span>
                                <User className="w-[24px] h-[24px] rounded-xl border" />
                            </div>
                        </div>
                        <Tooltip id="challengerUsername" />
                        <Tooltip id="acceptorUsername" />
                    </div>
                    <div className="flex flex-col justify-center items-center border rounded mb-2 w-[90%] mx-auto shadow-md">
                        <span className='w-full bg-[#F8F9FA] py-2 px-4 text-center border-b text-sm'>
                        Opponent का एक टोकन खुलने के बाद तुरंत यदि आप गेम लेफ्ट करते हो तो Opponent को 30% Win कर दिया जाएगा | Auto Exit के कैश में Admin का निर्णय ही अंतिम होगा जिससे आपको मान न होगा | लेकिन यदि आप गेम को जान बूझकर Auto Exit में छोड़ देते हैं तो आपको Loss कर दिया जाएगा | ध्यान रहे यदि किसी भी कैश में 2 Goti ओपन हो जाती है तो आप पूरा गेम Loss ही होंगे ?
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center border rounded mb-2 w-[90%] mx-auto shadow-md">
                        <span className="w-full bg-[#F8F9FA] py-2 px-4 text-center border-b ">
                            Room Code
                        </span>
                        <div className="flex justify-between gap-2 items-center h-[38px] mt-8 w-[90%] mx-4">
                            {!battleDetails?.roomCode ? (
                                battleDetails?.challengerId ===
                                    user?.userdetails?.id &&
                                battleDetails?.status !== 'Cancelled' ? (
                                    <>
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            maxLength={8}
                                            minLength={8}
                                            placeholder="Paste Room Code"
                                            className="pl-2 w-[80%] border rounded h-[38px]"
                                            onChange={(e: any) =>
                                                setRoomCode(
                                                    e.target.value?.trim()
                                                )
                                            }
                                        />
                                        <button
                                            className="bg-[#0d6efd] text-[#fff] w-[120px] rounded h-[38px]"
                                            onClick={onSubmitCode}
                                        >
                                            Send
                                        </button>
                                    </>
                                ) : battleDetails?.status === 'Cancelled' ? (
                                    <div className="mx-auto font-bold text-red-500">
                                        Cancelled
                                    </div>
                                ) : (
                                    <p className="mx-auto font-bold">
                                        Please wait for room code...
                                    </p>
                                )
                            ) : (
                                <span className="mx-auto font-bold">
                                    {battleDetails?.roomCode || roomCode}
                                </span>
                            )}
                        </div>

                        <div className="flex flex-col justify-center items-center w-[100%] gap-4 my-4">
                            <button
                                className="bg-[#0d6efd] text-[#fff] w-[90%] rounded h-[42px] "
                                onClick={() => {
                                    if (battleDetails?.roomCode || roomCode) {
                                        navigator.clipboard.writeText(
                                            battleDetails?.roomCode || roomCode
                                        );
                                        toast('Code Copied', {
                                            type: 'success',
                                        });
                                    } else {
                                        toast('Please Enter Room Code', {
                                            type: 'error',
                                        });
                                    }
                                }}
                            >
                                <p className="flex items-center justify-center gap-2">
                                    Copy Code
                                    <MdContentCopy />
                                </p>
                            </button>
                            <button
                                className="bg-green-700 text-[#fff] w-[90%] rounded h-[42px]"
                                onClick={() => openLudoGame()}
                            >
                                Open Ludo
                            </button>
                        </div>
                    </div>
                    {!battleDetails?.winnerId
                        ? renderGameResults()
                        : renderFinalResults()}
                    <div className="flex flex-col justify-center items-center border rounded mb-2 w-[90%] mx-auto shadow-md">
                        <span className="w-full bg-[#F8F9FA] py-2 text-center border-b ">
                            Penalty
                        </span>
                        <table className="w-full my-2 text-center ">
                            <thead className="text-sm text-center">
                                <tr>
                                    <th>Amount</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody className="text-sm">
                                <tr>
                                    <td>₹50</td>
                                    <td>Fraud/Fake Screenshot</td>
                                </tr>
                                <tr>
                                    <td>₹50</td>
                                    <td>Wrong Update</td>
                                </tr>
                                {/* <tr>
                                    <td>₹50</td>
                                    <td>No Update</td>
                                </tr>
                                <tr>
                                    <td>₹25</td>
                                    <td>Abusing</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                    {open && (
                        <UploadImgDialog
                            isOpen={open}
                            onClose={() => {
                                setIsOpen(false);
                                refetch();
                            }}
                            status={status}
                            battle_id={battle_id ? battle_id : ''}
                            battleDetails={battleDetails}
                        />
                    )}
                </>
            )}
            {showGameRules && (
                <GameRules
                    isOpen={showGameRules}
                    onClose={() => setShowGameRules(false)}
                />
            )}
        </Layout>
    );
};

export default Room;
