import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../Context/UserContext';
import { IoMdArrowBack } from 'react-icons/io';
import { useGetKyc } from '../../apis/hooks/useKYC';

const WalletBoxes = () => {
    const { user } = useUserData();
    const navigate = useNavigate();
    const { data: kycData, isLoading: loading } = useGetKyc({
        userId: user?.userdetails?.id,
    });

    return (
        <section className="flex flex-col justify-center items-center mx-auto w-[90%] mt-[20px] ">
            <div className="flex justify-between w-full mb-4">
                <button
                    className="bg-[#0d6efd] text-[#fff] rounded p-2 flex gap-2 items-center"
                    onClick={() => navigate('/home')}
                >
                    <IoMdArrowBack title="Back" size={20} fontWeight={700} />
                    Back
                </button>
                <button
                    className="text-[#0d6efd] border-[#0d6efd] border hover:bg-[#0d6efd] hover:text-white p-2  rounded "
                    onClick={() => navigate('/history')}
                >
                    Wallet History
                </button>
            </div>

            <div className="flex flex-col items-center justify-center mb-2 border rounded">
                <span className="w-full bg-[#F8F9FA] py-2 px-4 text-center border-b ">
                    Deposit Chips
                </span>
                <div className="p-4 mx-auto border border-t-0">
                    <div className=" bg-[#CFE2FF]  border-[1px] text-center rounded p-4  mb-4 flex flex-col border-blue-500">
                        यह चिप्स Spin & Win एवं Buy की गई चिप्स है इनसे सिर्फ़
                        गेम खेले जा सकते है ॥ Bank या UPI में निकाला नहीं जा
                        सकता है
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <span>Total Chips</span>
                        <span>{user?.userdetails?.wallet?.balance ?? 0}</span>
                    </div>
                    <button
                        className="bg-[#0d6efd] text-[20px] text-[#fff] w-full rounded-lg h-[48px] mt-4"
                        onClick={() => navigate(`/wallet/addWallet`)}
                    >
                        ADD
                    </button>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center mb-2 border rounded">
                <span className="w-full bg-[#F8F9FA] py-2 px-4 text-center border-b ">
                    Winning Chips
                </span>
                <div className="p-4 mx-auto border border-t-0">
                    <div className=" bg-[#CFE2FF]  border-[1px] text-center rounded p-4  mb-4 flex flex-col border-blue-500">
                        यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है इन्हें
                        Bank या UPI में निकाला जा सकता है ॥ इन चिप्स से गेम भी
                        खेला जा सकता है
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <span>Chips</span>
                        <span>{user?.userdetails?.wallet?.balance ?? 0}</span>
                    </div>

                    <button
                        className={`text-[#fff] text-[20px] w-full rounded-lg h-[48px] mt-4 
						${
                            // !user?.userdetails?.kyc ||
                            // !user?.userdetails?.kyc?.isKYCVerified
                            //     ? 'cursor-not-allowed bg-blue-300'
                            // : 'bg-[#0d6efd] '
                            'bg-[#0d6efd]'
                        }`}
                        // disabled={
                        //     !user?.userdetails?.kyc ||
                        //     !user?.userdetails?.kyc?.isKYCVerified
                        // }
                        onClick={() => {
                            kycData?.status === 'Approved'
                                ? navigate('/Withdraw')
                                : navigate('/KYC');
                        }}
                    >
                        {kycData?.status === 'Approved'
                            ? 'Withdraw'
                            : 'KYC Pending'}
                    </button>
                </div>
            </div>
        </section>
    );
};

export default WalletBoxes;
