import { useEffect, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import bank from '../../assets/Blank.png';
import { useNavigate } from 'react-router-dom';
import WithdrawalUPI from '../../components/WithdrawalUPI/WithdrawalUPI';
import WithdrawalBank from '../../components/WithdrawalBank/WithdrawalBank';
import useGetAccountDetails from '../../apis/hooks/useWithdrawalDetailsGet';
import { useCreateWithdrawal } from '../../apis/hooks/useCreateWithdrawal';
import { toast } from 'react-toastify';
import { useUserData } from '../../Context/UserContext';
import Loader from '../../components/Common/Loader/Loader';
import { useGetVisibilityByModule } from '../../apis/hooks/useVisibility';
import sleepingImg from '../../../src/assets/sleeping.avif';
import AlertSection from '../../components/Common/AlertSection/AlertSection';
import { BsBank2 } from 'react-icons/bs';

const Withdrawal = () => {
    const navigate = useNavigate();
    const [amount, setAmount] = useState('');
    const [expanded, setExpanded] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<string>();
    const [primaryChoice, setPrimaryChoice] = useState<string>('');

    const { user } = useUserData();
    const { data: visibilityData, isLoading: loadingVisibility } =
        useGetVisibilityByModule({
            userId: user?.userdetails?.id,
            name: 'withdraw',
        });

    const { data: accountData, isLoading: loadingAccountDetails } =
        useGetAccountDetails({
            userId: user?.userdetails?.id,
        });

    const { mutate: createWithdrawal, isLoading: isSubmitting } =
        useCreateWithdrawal();

    const handleChange = (panel: string) => (isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : null);
    };

    useEffect(() => {
        if (accountData?.data?.success) {
            setPrimaryChoice(accountData?.data?.data?.primaryChoice);
            setActiveTab(accountData?.data?.data?.primaryChoice);
        }
    }, [accountData]);

    const confirmWithdraw = () => {
        const data = {
            amount: +amount,
            userId: user?.userdetails?.id,
        };
        createWithdrawal(data, {
            onSuccess: (res: any) => {
                if (res?.data?.success) {
                    toast(res?.data?.message ?? 'Withdraw Request Submitted', {
                        type: 'success',
                    });
                } else {
                    toast(res?.data?.message ?? 'Try Again', { type: 'error' });
                }
            },
            onError: (error: any) => {
                toast(error?.message ?? 'Something went wrong', {
                    type: 'error',
                });
            },
        });
    };

    if (loadingVisibility) {
        return (
            <Layout>
                <div className="flex items-center justify-center h-screen">
                    <Loader scale={3} />
                </div>
            </Layout>
        );
    }

    if (!visibilityData?.isVisible) {
        return (
            <Layout>
                <div className="flex flex-col gap-4 items-center w-[90%] mx-auto">
                    <h1 className="p-2 font-semibold bg-gray-300 rounded-md">
                        Withdraw Currently Not Available
                    </h1>
                    <p>
                        <span className="font-semibold underline">
                            Reason:{' '}
                        </span>
                        {visibilityData?.reason}
                    </p>
                    <img
                        src={sleepingImg}
                        alt="withdrawal"
                        height={400}
                        width={400}
                    />
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="flex flex-col gap-4 w-[90%] mx-auto">
                {loadingAccountDetails ? (
                    <div className="flex items-center justify-center h-20">
                        <Loader scale={2} />
                    </div>
                ) : (
                    <>
                        {!primaryChoice && (
                            //     <p>
                            //         Your Primary Withdraw account is {primaryChoice}
                            //     </p>
                            // ) : (
                            <p className="font-semibold text-red-600">
                                Your account details are missing. Please select
                                and submit your primary withdrawal account to
                                proceed.
                            </p>
                        )}

                        {/* Withdrawal Options */}
                        {/* <div className="transition-all duration-300 border rounded-md shadow-md">
                            <div
                                className={`flex items-center justify-between p-4 cursor-pointer rounded-lg ${primaryChoice === 'UPI' ? 'bg-gray-300' : ''}`}
                                onClick={() => {
                                    handleChange('panel1')(
                                        expanded !== 'panel1'
                                    );
                                    setPrimaryChoice('UPI');
                                }}
                            >
                                <div className="flex items-center gap-4">
                                    <img
                                        src={`https://gotkingimages.s3.ap-southeast-2.amazonaws.com/images/upi.webp`}
                                        className="w-10 h-5"
                                    />
                                    <div>
                                        <p className="font-bold">
                                            Withdrawal By UPI
                                        </p>
                                        {expanded === 'panel1' && (
                                            <p className="text-red-600 transition-opacity duration-300">
                                                Minimum Withdrawal amount ₹95
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div>{expanded === 'panel1' ? '-' : '+'}</div>
                            </div>
                            {expanded === 'panel1' && (
                                <div className="p-4 transition-all duration-300 opacity-100">
                                    <WithdrawalUPI
                                        data={accountData?.data?.data}
                                    />
                                </div>
                            )}
                        </div> */}

                        {/* <div className="transition-all duration-300 border rounded-md shadow-md">
                            <div
                                className={`flex items-center justify-between p-4 cursor-pointer rounded-lg ${primaryChoice === 'BANK' ? 'bg-gray-300' : ''}`}
                                onClick={() => {
                                    handleChange('panel2')(
                                        expanded !== 'panel2'
                                    );
                                    setPrimaryChoice('BANK');
                                }}
                            >
                                <div className="flex items-center gap-4">
                                    <img src={bank} className="w-10 h-7" />
                                    <div>
                                        <p className="font-bold">
                                            Withdrawal By Bank
                                        </p>
                                        {expanded === 'panel2' && (
                                            <p className="text-red-600 transition-opacity duration-300">
                                                Maximum Withdrawal amount ₹10000
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div>{expanded === 'panel2' ? '-' : '+'}</div>
                            </div>
                            {expanded === 'panel2' && (
                                <div className="p-4 transition-all duration-300 opacity-100">
                                    <WithdrawalBank
                                        data={accountData?.data?.data}
                                    />
                                </div>
                            )}
                        </div> */}

                        <div>
                            <AlertSection
                                alertType="INFO"
                                message="User अपने बैंक डिटैल्ज़ और यूपीआई सही डाले, धन्यवाद"
                                className="p-2 "
                            />
                            <br />
                            <AlertSection
                                alertType="INFO"
                                message="Withdrawal में 1 से 10 मिनट का समय लग सकता है।"
                                className="p-2 bg-green-400"
                            />
                            <br />
                            <div className="flex gap-4 p-2 border border-red-400 rounded-md">
                                <div>
                                    <BsBank2 size={28} />
                                </div>
                                <div>
                                    <p>Minimum Withdrawal amount ₹95</p>
                                    <p>Maximum Withdrawal amount ₹10000</p>
                                </div>
                            </div>
                        </div>

                        {/* Tabs for UPI and Bank Withdrawal */}
                        <div className="my-4">
                            <div className="flex border-b">
                                <button
                                    className={`flex-1 py-2 px-4 text-center transition duration-300 ${activeTab === 'UPI' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
                                    onClick={() => setActiveTab('UPI')}
                                >
                                    UPI
                                </button>
                                <button
                                    className={`flex-1 py-2 px-4 text-center transition duration-300 ${activeTab === 'BANK' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
                                    onClick={() => setActiveTab('BANK')}
                                >
                                    Bank
                                </button>
                            </div>

                            <div className="p-2">
                                {activeTab === 'UPI' && (
                                    <WithdrawalUPI
                                        data={accountData?.data?.data}
                                    />
                                )}
                                {activeTab === 'BANK' && (
                                    <WithdrawalBank
                                        data={accountData?.data?.data}
                                    />
                                )}
                            </div>
                        </div>

                        <div>
                            <span>Withdraw Amount</span>
                            <div className="flex my-4 border rounded">
                                <span className="bg-[#F8F9FA] w-[42px] border-r text-center py-2">
                                    ₹
                                </span>
                                <input
                                    type="number"
                                    placeholder="Amount"
                                    value={amount}
                                    className="w-full h-[38px] focus:outline-none ml-2"
                                    onChange={(e) =>
                                        setAmount(e.target.value?.trim())
                                    }
                                />
                            </div>
                            <div className="w-full transition-all duration-300 opacity-100">
                                <button
                                    className={`w-full px-4 py-2 text-white transition-all duration-300 rounded ${
                                        amount
                                            ? 'bg-blue-500 hover:bg-blue-600'
                                            : 'bg-gray-400 cursor-not-allowed opacity-60'
                                    }`}
                                    onClick={confirmWithdraw}
                                    disabled={isSubmitting || !amount}
                                >
                                    <div className="flex items-center justify-center gap-4">
                                        {isSubmitting
                                            ? 'Submitting...'
                                            : 'Confirm Withdraw'}
                                        {isSubmitting && (
                                            <Loader scale={2} color="white" />
                                        )}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Layout>
    );
};

export default Withdrawal;
