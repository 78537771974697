import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from '../Config/PrivateRoute';
// import Loader from '../components/Common/Loader/Loader'
import Layout from '../components/Common/Layout/Layout';
import PlayArea from '../pages/PlayArea/PlayArea';
import Wallet from '../pages/Wallet/Wallet';
import LandingPage from '../pages/LandingPage/LandingPage';
import History from '../pages/History/History';
import Profile from '../pages/Profile/Profile';
import AddWallet from '../pages/AddWallet/AddWallet';
import PrePayment from '../pages/PrePayment/PrePayment';
import Room from '../pages/Room/Room';
import IntoPage from '../pages/IntoPage/IntoPage';
import ReferAndEarn from '../pages/Refer&Earn/Refer&Earn';
import Withdrawal from '../pages/Withdrawal/Withdrawal';
import WithdrawalUPI from '../components/WithdrawalUPI/WithdrawalUPI';
import WithdrawalBank from '../components/WithdrawalBank/WithdrawalBank';
import Support from '../pages/Support/Support';
import Register from '../components/Register/Register';
import Login from '../components/Login/Login';
import OTP from '../components/OTP/OTP';
import Policy from '../pages/Policy/Policy';
import Maintainance from '../pages/Maintainance/maintainance';
import Kyc from '../components/KYC/Kyc';

type AmbassadorRoutesProps = object;

const kelloRoutes: React.FC<AmbassadorRoutesProps> = () => {
    const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

    if (isMaintenanceMode) {
        return (
            <Suspense fallback={<Layout />}>
                <Routes>
                    <Route path="*" element={<Maintainance />} />
                </Routes>
            </Suspense>
        );
    }
    return (
        <Suspense fallback={<Layout />}>
            <Routes>
                <Route path="" element={<IntoPage />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route
                    path="/register/:referCode?"
                    element={<Register />}
                ></Route>
                {/* <Route path="/otp/:otp" element={<OTP />}></Route> */}
                {/* <Route path="/otp/:emailId" element={<OTP />}></Route> */}
                <Route path="/otp" element={<OTP />}></Route>

                <Route element={<PrivateRoutes />}>
                    <Route path="/home" element={<LandingPage />}></Route>
                    <Route
                        path="/battle/:type/:id"
                        element={<PlayArea />}
                    ></Route>
                    <Route path="/wallet" element={<Wallet />}></Route>;
                    <Route
                        path="/wallet/addWallet"
                        element={<AddWallet />}
                    ></Route>
                    <Route path="/history" element={<History />}></Route>
                    <Route path="/support" element={<Support />}></Route>
                    <Route path="/payment" element={<PrePayment />}></Route>
                    <Route path="/profile" element={<Profile />}></Route>
                    <Route path="/room/:id" element={<Room />}></Route>
                    <Route path="/Withdraw" element={<Withdrawal />}></Route>
                    <Route
                        path="/WithdrawalUPI"
                        element={<WithdrawalUPI />}
                    ></Route>
                    <Route
                        path="/WithdrawalBank"
                        element={<WithdrawalBank />}
                    ></Route>
                    <Route
                        path="/refer&earn"
                        element={<ReferAndEarn />}
                    ></Route>
                    <Route path="/policy" element={<Policy />}></Route>
                    <Route path="/kyc" element={<Kyc />}></Route>
                </Route>
            </Routes>
        </Suspense>
    );
};

export default kelloRoutes;
