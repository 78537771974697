import Alert from '../../../assets/Alert';
import RedTriangleSolidWarning from '../../../assets/RedTriangleSolidWarning';
import { RiProhibited2Line } from 'react-icons/ri';
import { IoInformationCircleOutline } from 'react-icons/io5';

const AlertSection = ({
    message,
    alertType,
    className,
}: {
    message: string;
    alertType: 'PROHIBITED' | 'WARNING' | 'INFO';
    className: string;
}) => {
    switch (alertType) {
        case 'PROHIBITED':
            break;

        default:
            break;
    }
    return (
        <div
            className={`${className} flex gap-2 bg-gray-300 rounded-md text-sm font-extrabold text-[#664D03]`}
        >
            {/* <RedTriangleSolidWarning className="w-[50px] h-[20px] " /> */}
            {alertType === 'PROHIBITED' && (
                <RiProhibited2Line className="w-[50px] h-[20px] " />
            )}
            {alertType === 'WARNING' && (
                <RedTriangleSolidWarning className="w-[50px] h-[20px] " />
            )}
            {alertType === 'INFO' && (
                <IoInformationCircleOutline className="w-[50px] h-[20px] " />
            )}
            {/* <RiProhibited2Line className="w-[50px] h-[20px] " /> */}
            {/* Bank की समस्या के कारण UPI withdrawal बंद हैं असुविधा के लिए खेद है
            जल्द ही दुबारा चालू हो जाएगा तब तक आप बैंक से Withdrawal ले सकते
            हैं।। Notice:- ! | 🙏 */}
            {message}
        </div>
    );
};

export default AlertSection;
