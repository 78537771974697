import { useNavigate } from 'react-router';
import User from '../../assets/UserProfile';
import vsImg from '../../assets/VS.webp';
import { useEffect, useState } from 'react';
import Loader from '../Common/Loader/Loader';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
    useAcceptChallenge,
    useCreateBattle,
    useChangeBattleStatus,
} from '../../apis/hooks/useUpdatePlayerBattleResponse';
import { Tooltip } from 'react-tooltip';
import useDeleteBattle from '../../apis/hooks/useDeleteBattle';
import { useUserData } from '../../Context/UserContext';
import {
    useGetAllBattle,
    useGetBattle,
    useUpdateBattle,
} from '../../apis/hooks/useBattles';
import AlertSection from '../Common/AlertSection/AlertSection';
import { io } from 'socket.io-client';
import { useSocket } from '../../Context/SocketContext';

const PlayBox = ({ message }: { message: any }) => {
    const navigate = useNavigate();
    const { user } = useUserData();
    const [noBattlesFound, setNoBattlesFound] = useState(false);
    const [runningMatch, setRunningMatch] = useState([]);
    const [amount, setAmount] = useState('');
    const { type, id: battle_id } = useParams();

    const [battles, setBattles] = useState<any>([]);

    const { data: battlesData, refetch, isLoading } = useGetAllBattle();
    const [sortedBattles, setSortedBattles] = useState<any>([]);

    const { mutate: createBattle, isLoading: createBattleLoading } =
        useCreateBattle();
    const { mutate: changeBattleStatus } = useChangeBattleStatus();
    const { mutate: acceptChallenge } = useAcceptChallenge();
    const { mutate: deleteBattle } = useDeleteBattle();
    const { mutate: cancelBattleStatus } = useUpdateBattle();
    const { socket } = useSocket();

    const fakeBattles = [
        {
            id: 'fake1_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challengerId: 'challengerId_ti7qq04g1dy0x4ppgfejx8usj',
            acceptorId: 'acceptor_qskf9o274nqj72ko6q0e4i9wz',
            amount: 500,
            status: 'Ready',
            cancelReason: '',
            cancelDescription: '',
            roomCode: '07378737',
            challengerResponse: 'Cancelled',
            acceptorResponse: 'Cancelled',
            challengerResponseFile: '',
            acceptorResponseFile: '',
            challengerResponseFileData: '',
            acceptorResponseFileData: '',
            winnerId: '',
            challengerUsername: 'Mohit',
            acceptorUsername: 'Subham',
        },
        {
            id: 'fake2_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challengerId: 'challengerId_ti7qq04g1dy0x4ppgfejx8usj',
            acceptorId: 'acceptor_qskf9o274nqj72ko6q0e4i9wz',
            amount: 100,
            status: 'Ready',
            cancelReason: '',
            cancelDescription: '',
            roomCode: '07378737',
            challengerResponse: 'Cancelled',
            acceptorResponse: 'Cancelled',
            challengerResponseFile: '',
            acceptorResponseFile: '',
            challengerResponseFileData: '',
            acceptorResponseFileData: '',
            winnerId: '',
            challengerUsername: 'Ansar',
            acceptorUsername: 'Shivam',
        },
        {
            id: 'fake3_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challengerId: 'challengerId_ti7qq04g1dy0x4ppgfejx8usj',
            acceptorId: 'acceptor_qskf9o274nqj72ko6q0e4i9wz',
            amount: 50,
            status: 'Ready',
            cancelReason: '',
            cancelDescription: '',
            roomCode: '07378737',
            challengerResponse: 'Cancelled',
            acceptorResponse: 'Cancelled',
            challengerResponseFile: '',
            acceptorResponseFile: '',
            challengerResponseFileData: '',
            acceptorResponseFileData: '',
            winnerId: '',
            challengerUsername: 'Sohil',
            acceptorUsername: 'Arman Khan',
        },
        {
            id: 'fake4_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challengerId: 'challengerId_ti7qq04g1dy0x4ppgfejx8usj',
            acceptorId: 'acceptor_qskf9o274nqj72ko6q0e4i9wz',
            amount: 100,
            status: 'Ready',
            cancelReason: '',
            cancelDescription: '',
            roomCode: '07378737',
            challengerResponse: 'Cancelled',
            acceptorResponse: 'Cancelled',
            challengerResponseFile: '',
            acceptorResponseFile: '',
            challengerResponseFileData: '',
            acceptorResponseFileData: '',
            winnerId: '',
            challengerUsername: 'Dipti',
            acceptorUsername: 'Ritesh',
        },
        {
            id: 'fake5_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challengerId: 'challengerId_ti7qq04g1dy0x4ppgfejx8usj',
            acceptorId: 'acceptor_qskf9o274nqj72ko6q0e4i9wz',
            amount: 2000,
            status: 'Ready',
            cancelReason: '',
            cancelDescription: '',
            roomCode: '07378737',
            challengerResponse: 'Cancelled',
            acceptorResponse: 'Cancelled',
            challengerResponseFile: '',
            acceptorResponseFile: '',
            challengerResponseFileData: '',
            acceptorResponseFileData: '',
            winnerId: '',
            challengerUsername: 'Sahil',
            acceptorUsername: 'Zishan Khan',
        },
        {
            id: 'fake6_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challengerId: 'challengerId_ti7qq04g1dy0x4ppgfejx8usj',
            acceptorId: 'acceptor_qskf9o274nqj72ko6q0e4i9wz',
            amount: 100,
            status: 'Ready',
            cancelReason: '',
            cancelDescription: '',
            roomCode: '07378737',
            challengerResponse: 'Cancelled',
            acceptorResponse: 'Cancelled',
            challengerResponseFile: '',
            acceptorResponseFile: '',
            challengerResponseFileData: '',
            acceptorResponseFileData: '',
            winnerId: '',
            challengerUsername: 'Usman Saif',
            acceptorUsername: 'Md. Abdul',
        },
        {
            id: 'fake7_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challengerId: 'challengerId_ti7qq04g1dy0x4ppgfejx8usj',
            acceptorId: 'acceptor_qskf9o274nqj72ko6q0e4i9wz',
            amount: 5000,
            status: 'Ready',
            cancelReason: '',
            cancelDescription: '',
            roomCode: '07378737',
            challengerResponse: 'Cancelled',
            acceptorResponse: 'Cancelled',
            challengerResponseFile: '',
            acceptorResponseFile: '',
            challengerResponseFileData: '',
            acceptorResponseFileData: '',
            winnerId: '',
            challengerUsername: 'Arif',
            acceptorUsername: 'Md. Abbas',
        },
        {
            id: 'fake8_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challengerId: 'challengerId_ti7qq04g1dy0x4ppgfejx8usj',
            acceptorId: 'acceptor_qskf9o274nqj72ko6q0e4i9wz',
            amount: 100,
            status: 'Ready',
            cancelReason: '',
            cancelDescription: '',
            roomCode: '07378737',
            challengerResponse: 'Cancelled',
            acceptorResponse: 'Cancelled',
            challengerResponseFile: '',
            acceptorResponseFile: '',
            challengerResponseFileData: '',
            acceptorResponseFileData: '',
            winnerId: '',
            challengerUsername: 'Kohli',
            acceptorUsername: 'Irfan',
        },
        {
            id: 'fake9_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challengerId: 'challengerId_ti7qq04g1dy0x4ppgfejx8usj',
            acceptorId: 'acceptor_qskf9o274nqj72ko6q0e4i9wz',
            amount: 350,
            status: 'Ready',
            cancelReason: '',
            cancelDescription: '',
            roomCode: '07378737',
            challengerResponse: 'Cancelled',
            acceptorResponse: 'Cancelled',
            challengerResponseFile: '',
            acceptorResponseFile: '',
            challengerResponseFileData: '',
            acceptorResponseFileData: '',
            winnerId: '',
            challengerUsername: 'Saif Ali',
            acceptorUsername: 'Md. Abdul Khan',
        },
        {
            id: 'fake10_4uow1nvvxzdjogx3bmw0thyw9',
            battleTypeId: 'b744r8hyegddoqfng6r9z2hzz',
            challengerId: 'challengerId_ti7qq04g1dy0x4ppgfejx8usj',
            acceptorId: 'acceptor_qskf9o274nqj72ko6q0e4i9wz',
            amount: 900,
            status: 'Ready',
            cancelReason: '',
            cancelDescription: '',
            roomCode: '07378737',
            challengerResponse: 'Cancelled',
            acceptorResponse: 'Cancelled',
            challengerResponseFile: '',
            acceptorResponseFile: '',
            challengerResponseFileData: '',
            acceptorResponseFileData: '',
            winnerId: '',
            challengerUsername: 'Himanshu',
            acceptorUsername: 'Rohit',
        },
    ];

    useEffect(() => {
        if (battles) {
            const sortedData = battles.sort((a: any, b: any) => {
                if (a.status === 'Pending' && b.status !== 'Pending') {
                    return -1;
                } else if (a.status !== 'Pending' && b.status === 'Pending') {
                    return 1;
                } else {
                    return 0;
                }
            });
            setSortedBattles(sortedData);
        }
    }, [battles]);

    useEffect(() => {
        // if (battlesData?.length) {
        setBattles(battlesData);
        // } else {
        // setNoBattlesFound(true);
        // }
    }, [battlesData]);

    const isAmountValid = (amount: string) => {
        return (
            amount.trim() !== '' &&
            Number(amount) % 50 === 0 &&
            Number(amount) <= 10000
        );
    };

    const onSubmit = async () => {
        if (
            isAmountValid(amount) &&
            parseInt(amount) >= 50 &&
            parseInt(amount) <= 10000
        ) {
            const payload = {
                battleTypeId: battle_id,
                challengerId: user?.userdetails?.id,
                amount: parseInt(amount),
            };

            createBattle(payload, {
                onSuccess: (res: any) => {
                    if (res?.data?.success) {
                        setAmount('');
                        // socket.emit('newMessage', {
                        //     message: 'Battle Created Successfully',
                        //     data: res?.data?.data,
                        // });
                        refetch();

                        // toast('Battle Created Successfully', {
                        //     type: 'success',
                        // });
                    }
                    if (res?.data?.error) {
                        toast(res?.data?.message ?? 'Unable to create battle', {
                            type: 'error',
                        });
                    }
                },
                onError: (error: any) => {
                    toast(error?.message ?? 'Something went wrong', {
                        type: 'error',
                    });
                },
            });
        } else {
            toast(
                'please enter valid values min 50 and max 10,000 i.e 50,100,150 etc',
                {
                    type: 'error',
                }
            );
        }
    };

    const handleCancelBattle = (battle: any, type: string) => {
        const data = {
            battleId: battle?.id,
            status: 'Cancelled',
            cancelReason: `Battle ${type} By ${type === 'Cancelled' ? 'Challenger' : 'Acceptor'}`,
            cancelDescription: `Battle ${type} By ${type === 'Cancelled' ? 'Challenger' : 'Acceptor'}`,
            userId: user?.userdetails?.id,
        };

        cancelBattleStatus(data, {
            onSuccess: (res: any) => {
                if (res?.data?.error) {
                    toast(res?.data?.message ?? 'Unable to update battle', {
                        type: 'error',
                    });
                }

                if (res?.data?.success) {
                    refetch();

                    // socket.emit('newMessage', {
                    //     message: 'Battle Cancelled Successfully',
                    //     data: res?.data?.data,
                    // });
                    // toast(
                    //     res?.data?.message ?? 'Battle Cancelled Successfully',
                    //     {
                    //         type: 'success',
                    //     }
                    // );
                }
            },
            onError: (error: any) => {
                toast(error?.message ?? 'Something went wrong', {
                    type: 'error',
                });
            },
        });
    };

    const handleDelete = (id: string) => {
        const data = {
            battle_id: id,
        };
        deleteBattle(data, {
            onSuccess: (res: any) => {
                if (res?.data?.success) {
                    // socket.emit('newMessage', {
                    //     message: 'Battle Deleted Successfully',
                    //     data: res?.data?.data,
                    // });
                    refetch();
                    // toast(res?.data?.message || 'Battle deleted successfully', {
                    //     type: 'success',
                    // });
                }
            },
            onError: (e: any) => {
                if (e) {
                    toast(e?.message || 'Try Again!', { type: 'error' });
                }
            },
        });
    };

    const handleAcceptorResponse = (battle: any) => {
        const acceptorId = user?.userdetails?.id;

        const payload = {
            battleId: battle?.id,
            acceptorId,
            amount: battle?.amount,
        };

        acceptChallenge(payload, {
            onSuccess: (data: any) => {
                if (data?.data?.error) {
                    toast(data?.data?.message, { type: 'error' });
                } else {
                    // socket.emit('newMessage', {
                    //     message: 'Challenge Accepted Successfully',
                    //     data: { id: battle?.id },
                    // });
                    refetch();
                    // toast(data?.data?.message, { type: 'success' });
                }
            },
            onError: (error: any) => {
                toast(
                    error?.message ? error?.message : 'Something went wrong',
                    { type: 'error' }
                );
            },
        });
    };

    const handleBattleStatus = (battle: any) => {
        const payload = {
            battleId: battle?.id,
            status: 'View',
        };
        changeBattleStatus(payload, {
            onSuccess: (data: any) => {
                if (data?.data?.error) {
                    toast(data?.data?.message, { type: 'error' });
                } else {
                    // socket.emit('newMessage', {
                    //     message: 'Battle Status Changed Successfully',
                    //     data: { id: battle?.id },
                    // });
                    refetch();
                    // toast(data?.data?.message, { type: 'success' });
                }
            },
            onError: (error: any) => {
                toast(error?.message ?? 'Something went wrong', {
                    type: 'error',
                });
            },
        });
    };

    const redirectToRoomCode = (battle: any) => {
        navigate(`/room/${battle?.id}`);
    };

    useEffect(() => {
        console.log('PlayBox Message: ', message);
        if (message?.data?.data?.id) {
            console.log('========== IFFF ============');

            refetch();
            // const battleData = message?.data?.data;
            // console.log('battleData', battleData);

            // const remainingBattles = battles?.filter(
            //     (battle: any) => battle?.id !== battleData?.id
            // );
            // switch (message?.data?.action) {
            //     case 'create':
            //     case 'update':
            //         // filter out the this data and remove from existing array

            //         // append data at top of array
            //         setBattles([battleData, ...remainingBattles]);
            //         break;

            //     case 'cancel':
            //     case 'delete':
            //         setBattles(remainingBattles);
            //         break;

            //     case 'challengeAccepted':
            //         // filter out the this data and remove from existing array
            //         // append data at top of array
            //         // toast("Let's Play", { type: 'success' });
            //         setBattles([battleData, ...remainingBattles]);
            //         break;

            //     default:
            //         break;
            // }
        }
    }, [message]);

    const renderBattleOnGoing = (battle: any, index: number) => {
        const isChallenger = battle?.challengerId === user?.userdetails?.id;
        const isAcceptor = battle?.acceptorId === user?.userdetails?.id;

        return (
            <>
                <div className="bg-[#F8F9FA] py-2 px-4 flex justify-between border-b rounded border slide-in-left">
                    <span className="">Challenge set by</span>
                    <span className="font-bold text-lime-600">
                        Rs {battle?.amount}
                    </span>
                </div>
                <div className="flex justify-between p-2 border border-t-0 slide-in-left">
                    <div className="flex items-center gap-2">
                        <User className="w-[24px] h-[24px] rounded-xl border" />

                        {(() => {
                            switch (true) {
                                case isChallenger &&
                                    battle?.status === 'Pending':
                                    return (
                                        <span className="flex gap-2">
                                            <Loader /> Finding player
                                        </span>
                                    );

                                case isChallenger &&
                                    battle?.status === 'Ready' &&
                                    !battle?.roomCode:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.acceptorUsername?.slice(
                                                0,
                                                12
                                            ) + '...'}
                                        </span>
                                    );

                                case isAcceptor &&
                                    battle?.status === 'Ready' &&
                                    !battle?.roomCode:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.challengerUsername?.slice(
                                                0,
                                                12
                                            ) + '...'}
                                        </span>
                                    );

                                case !isChallenger &&
                                    !isAcceptor &&
                                    battle?.status === 'Ready' &&
                                    !battle?.roomCode:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.acceptorUsername?.slice(
                                                0,
                                                12
                                            ) + '...'}
                                        </span>
                                    );

                                case isChallenger &&
                                    battle?.status === 'View' &&
                                    !battle?.roomCode:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.acceptorUsername?.slice(
                                                0,
                                                12
                                            ) + '...'}
                                        </span>
                                    );

                                case isAcceptor &&
                                    battle?.status === 'View' &&
                                    !battle?.roomCode:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.challengerUsername?.slice(
                                                0,
                                                12
                                            ) + '...'}
                                        </span>
                                    );

                                default:
                                    return (
                                        <span className="flex gap-2">
                                            {battle?.challengerUsername?.slice(
                                                0,
                                                12
                                            ) + '...'}
                                        </span>
                                    );
                            }
                        })()}
                    </div>

                    {isChallenger && battle?.status === 'Pending' && (
                        <button
                            className="px-2 py-1 text-white bg-red-600 rounded"
                            onClick={() => handleDelete(battle?.id)}
                        >
                            Delete
                        </button>
                    )}

                    {!isChallenger && battle?.status === 'Pending' && (
                        <button
                            className="px-2 py-1 text-white bg-blue-600 rounded"
                            onClick={() => handleAcceptorResponse(battle)}
                        >
                            Play
                        </button>
                    )}

                    {isChallenger &&
                        battle?.status === 'Ready' &&
                        !battle?.roomCode && (
                            <div className="flex gap-2">
                                <button
                                    className="px-2 py-1 text-white bg-green-600 rounded"
                                    onClick={() => handleBattleStatus(battle)}
                                >
                                    Play
                                </button>
                                <button
                                    className="px-2 py-1 text-white bg-red-600 rounded"
                                    onClick={() =>
                                        handleCancelBattle(battle, 'Cancelled')
                                    }
                                >
                                    Cancel
                                </button>
                            </div>
                        )}

                    {isAcceptor &&
                        battle?.status === 'Ready' &&
                        !battle?.roomCode && (
                            <div className="flex gap-2 text-xs">
                                <button
                                    className="px-1 text-white bg-black rounded"
                                    // onClick={() => redirectToRoomCode(battle)}
                                >
                                    Requested
                                </button>
                                <button
                                    className="px-2 py-1 text-white bg-red-600 rounded"
                                    onClick={() =>
                                        handleCancelBattle(battle, 'Rejected')
                                    }
                                >
                                    Rejected
                                </button>
                            </div>
                        )}

                    {/* roomCode means battle Status is Playing, DRAW,  other cases are going on completed battle, like cancelled, Finished*/}
                    {((isChallenger || isAcceptor) && battle?.roomCode) ||
                    battle?.status === 'View' ? (
                        <div className="flex gap-2">
                            <button
                                className="px-2 py-1 text-white bg-green-600 rounded"
                                onClick={() => redirectToRoomCode(battle)}
                            >
                                View
                            </button>
                        </div>
                    ) : null}
                </div>
            </>
        );
    };

    const renderCompletedBattle = (battle: any, index: number) => {
        return (
            <div
                className="mb-6 transition ease-in-out transform"
                onClick={() => {
                    if (
                        battle?.challengerId === user?.userdetails?.id ||
                        battle?.acceptorId === user?.userdetails?.id
                    ) {
                        redirectToRoomCode(battle);
                    }
                }}
            >
                <div className="flex justify-between p-4 border rounded-md">
                    <div
                        className="flex gap-2 my-auto cursor-pointer"
                        data-tooltip-id="challengerUsername"
                        data-tooltip-content={battle?.challengerUsername}
                    >
                        <User className="w-[24px] h-[24px] rounded-xl border" />
                        <span>
                            {battle?.challengerUsername?.slice(0, 5) + '...'}
                        </span>
                    </div>
                    <div className="">
                        <img src={vsImg} className="w-[30px] h-10 mx-auto" />
                        <span className="font-bold text-lime-600">
                            Rs {battle?.amount}
                        </span>
                    </div>
                    <div
                        className="flex gap-2 my-auto cursor-pointer"
                        data-tooltip-id="acceptorUsername"
                        data-tooltip-content={battle?.acceptorUsername}
                    >
                        <span>
                            {battle?.acceptorUsername?.slice(0, 5) + '...'}
                        </span>
                        <User className="w-[24px] h-[24px] rounded-xl border" />
                    </div>
                </div>
                <Tooltip id="challengerUsername" />
                <Tooltip id="acceptorUsername" />
            </div>
        );
    };

    const ComingSoon = () => {
        return (
            <div className="text-center text-[24px] font-semibold">
                Coming Soon....
            </div>
        );
    };

    const renderBattleCard = (battle: any, index: number) => {
        const handleReadyBattle = () => {
            if (
                battle?.challengerId !== user?.userdetails?.id &&
                battle?.acceptorId !== user?.userdetails?.id
            ) {
                return renderCompletedBattle(battle, index);
            } else {
                return renderBattleOnGoing(battle, index);
            }
        };

        switch (battle?.status) {
            case 'Pending':
                return renderBattleOnGoing(battle, index);
            case 'Play':
            case 'Playing':
            case 'Draw':
            case 'Ready':
            case 'Semi Cancelled':
            case 'View':
                return handleReadyBattle();

            case 'Finished':
            case 'Cancelled':
                return renderCompletedBattle(battle, index);

            // case 'Ready':
            //     return handleReadyBattle();

            default:
                return ComingSoon();
        }
    };

    return (
        <section className="mx-auto w-[90%]">
            {/* <AlertSection
                message={
                    'Website के अंदर किसी भी तरह कि कोई भी समस्या आए तो तुरंत Whatsapp पर मैसेज करे'
                }
                className="p-2 text-black bg-blue-200"
                alertType="INFO"
            /> */}
            <AlertSection
                // message={
                //     '🔥न्यू धमाकेदार ऑफर,50 बंदे को रेफर करने पर 1 हजार रुपया का बोनस मिलेगा,🎁'
                // }
                message="⚠️अगर कोई यूजर गेम पूरा होने के बाद सही रिजल्ट नही डालता तो उसके 50 रुपए कि पेनल्टी लगेगी ⚠️"
                className="p-2 my-2 text-black bg-yellow-400"
                alertType="INFO"
            />
            <div className="py-4 rounded ">
                <div className="flex justify-between rounded h-[38px] gap-2 ">
                    <input
                        value={amount}
                        type="text"
                        inputMode="numeric"
                        placeholder="Amount"
                        className="w-full pl-4 border-2 border-gray-300 rounded h-[38px] focus:outline-none focus:border-blue-500"
                        onChange={(e: any) => setAmount(e.target.value.trim())}
                    />
                    <button
                        className={`w-[120px] rounded text-white ${
                            !isAmountValid(amount) || createBattleLoading
                                ? 'bg-gray-400 cursor-not-allowed opacity-60'
                                : 'bg-blue-600 hover:bg-blue-700 cursor-pointer'
                        }`}
                        disabled={
                            // isNaN(parseInt(amount)) ||
                            // parseInt(amount) < 50 ||
                            // parseInt(amount) > 10000 ||
                            !isAmountValid(amount) || createBattleLoading
                        }
                        onClick={() => onSubmit()}
                    >
                        Set
                    </button>
                </div>
            </div>

            <div className="flex justify-center w-full mt-2">
                {/* <hr className="h-[40px] w-[25%] my-[14px] border-[#000] border-t-2" /> */}

                <span className="text-center mx-1 text-[14px] sm:text-[16px] md:text-[20px] font-semiBold pb-1 mb-4">
                    🏆︎ Open Battles(Classic)🏆︎
                </span>
                {/* <hr className="h-[40px] w-[10%] md:w-[20%] my-[14px] border-[rgb(0,0,0)] border-t-2" /> */}
            </div>

            {isLoading ? (
                <div className="flex flex-col gap-8 items-center justify-center h-[300px]">
                    <Loader scale={4} />
                    <p>Please Wait. Loading...</p>
                </div>
            ) : (
                <></>
                // battles?.length === 0 && (
                //     <div className="text-center text-[24px] font-semibold">
                //         <h1 className="text-red-500">
                //             No Open Battles Found!!
                //         </h1>
                //     </div>
                // )
            )}

            {sortedBattles?.map((battle: any, index: any) => (
                <div
                    className="mb-6 transition ease-in-out transform"
                    key={index}
                >
                    {renderBattleCard(battle, index)}
                </div>
            ))}

            {/* FAKE BAttles */}
            {fakeBattles?.map((battle: any, index: any) => (
                <div
                    className="mb-6 transition ease-in-out transform"
                    key={index}
                >
                    {renderCompletedBattle(battle, index)}
                </div>
            ))}
        </section>
    );
};

export default PlayBox;
