import { Transition, Dialog } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';
import UploadImg from '../../assets/UploadImg';
import { toast } from 'react-toastify';
import { useUpdatePlayerBattleResponse } from '../../apis/hooks/useUpdatePlayerBattleResponse';
import { useUpdateBattle } from '../../apis/hooks/useBattles';
import { useUserData } from '../../Context/UserContext';
import Loader from '../Common/Loader/Loader';
import { useUploadFiles } from '../../apis/hooks/useUploadFiles';
import { useSocket } from '../../Context/SocketContext';

type Props = {
    onClose: () => void;
    isOpen: boolean;
    battle_id: string;
    status: string;
    battleDetails: any;
};

const chipsList = [
    {
        key: 0,
        type: 'Other',
    },
    {
        key: 1,
        type: 'Player Missing',
    },
    {
        key: 2,
        type: "Don't want to play",
    },
];
const UploadImgDialog = ({
    isOpen,
    onClose,
    battle_id,
    status,
    battleDetails,
}: Props) => {
    const fileInputRef = useRef<any>(null);
    const [fileData, setFileData] = useState<any>({});
    const [previewUrl, setPreviewUrl] = useState('');
    const [reason, setReason] = useState('');
    const [reasonIndex, setReasonIndex] = useState();
    const [description, setDescription] = useState('');
    const { mutate: submitResponse, isLoading } =
        useUpdatePlayerBattleResponse();
    const { mutate: updateStatus, isLoading: updateStatusLoading } =
        useUpdateBattle();
    const { mutate: uploadFile, isLoading: uploading } = useUploadFiles();

    const { user } = useUserData();
    const { message, socket } = useSocket();

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file?.size > 5 * 1024 * 1024) {
            return alert('File Size Must Be smaller then 5MB!');
        }
        if (file) {
            setFileData(file);
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader?.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const postResults = (status: string) => {
        if (status === 'Won') {
            handleFileUpload(fileData);
        } else if (status === 'Lost') {
            onSubmitStatus();
        }
    };

    const handleFileUpload = async (file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        await uploadFile(formData, {
            onSuccess: (data: any) => {
                if (data?.data?.id) {
                    onSubmitStatus(data?.data);
                } else {
                    toast(data?.message ?? 'Error while uploading file', {
                        type: 'error',
                    });
                    fileInputRef.current.value = '';
                }
            },
            onError: (error: any) => {
                fileInputRef.current.value = '';
                toast(error?.message ?? 'Something went wrong', {
                    type: 'error',
                });
            },
        });
    };

    const onSubmitStatus = async (data?: any) => {
        let payload = {};
        if (user?.userdetails?.id === battleDetails?.acceptorId) {
            payload = {
                battleId: battle_id,
                acceptorResponse: status,
                acceptorResponseFile: data?.id,
            };
        } else if (user?.userdetails?.id === battleDetails?.challengerId) {
            payload = {
                battleId: battle_id,
                challengerResponse: status,
                challengerResponseFile: data?.id,
            };
        } else {
            toast('Invalid user', { type: 'error' });
            return;
        }

        submitResponse(payload, {
            onSuccess: (res: any) => {
                if (res?.data?.error) {
                    toast(res?.data?.message ?? 'Unable to update battle', {
                        type: 'error',
                    });
                } else {
                    // socket.emit('newMessage', {
                    //     message: 'User Update there response',
                    //     data: res?.data?.data,
                    // });
                    onClose();
                    // toast(res?.data?.message ?? 'Battle Updated Successfully', {
                    //     type: 'success',
                    // });
                }
            },
            onError: (error: any) => {
                toast(error?.message ?? 'Something went wrong', {
                    type: 'error',
                });
            },
        });
    };

    const submitReason = async () => {
        const payload = {
            battleId: battle_id,
            status: 'Cancelled',
            cancelReason: reason || `Cancel By ${user?.userdetails?.username}`,
            cancelDescription:
                description || `Cancel By ${user?.userdetails?.username}`,
            userId: user?.userdetails?.id,
        };

        updateStatus(payload, {
            onSuccess: (res: any) => {
                if (res?.data?.error) {
                    toast(res?.data?.message ?? 'Unable to update battle', {
                        type: 'error',
                    });
                } else {
                    onClose();
                    // toast(res?.data?.message ?? 'Battle Updated Successfully', {
                    //     type: 'success',
                    // });
                }
            },
            onError: (error: any) => {
                toast(error?.message ?? 'Unable to update battle', {
                    type: 'error',
                });
                onClose();
            },
        });
    };

    const selectReason = (res: any) => {
        setReason(res?.type);
        setReasonIndex(res?.key);
    };

    useEffect(() => {
        setFileData(null);
    }, []);
    return (
        <Transition.Root show={isOpen} as={React.Fragment} appear>
            <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-25" />
                </Transition.Child>
                <div
                    className={`fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20 flex justify-center items-center`}
                >
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className={`transform shadow-2xl ring-1 ring-black ring-opacity-5 transition-all bg-white overflow-scroll w-[90%] mx-auto `}
                        >
                            {status !== 'Cancel' ? (
                                <div className="flex flex-col items-center justify-center gap-4 p-2 mx-auto rounded">
                                    {previewUrl && (
                                        <img
                                            src={previewUrl}
                                            className="w-full h-[300px] object-fill "
                                        />
                                    )}
                                    {status === 'Won' &&
                                        (!previewUrl ? (
                                            <>
                                                <button
                                                    className="bg-green-800 text-[#fff] w-full rounded p-2"
                                                    onClick={() =>
                                                        fileInputRef.current.click()
                                                    } // Trigger file input click
                                                >
                                                    Upload Image
                                                </button>
                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    accept="image/*"
                                                    className="hidden"
                                                    required
                                                    data-max-size="4096000"
                                                    id="upload_ipt"
                                                    onChange={handleFileChange}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    className="bg-[#0d6efd] text-[#fff] p-2 rounded"
                                                    onClick={() =>
                                                        fileInputRef.current.click()
                                                    } // Trigger file input click
                                                >
                                                    Replace Image
                                                </button>
                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    accept="image/*"
                                                    required
                                                    hidden
                                                    data-max-size="4096000"
                                                    id="upload_ipt"
                                                    onChange={handleFileChange}
                                                />
                                            </>
                                        ))}
                                    {(fileData || status === 'Lost') && (
                                        <button
                                            className="bg-red-600 text-[#fff] rounded p-2 w-full "
                                            onClick={() => postResults(status)}
                                            disabled={isLoading}
                                        >
                                            <div className="flex items-center justify-center gap-4">
                                                {isLoading || uploading
                                                    ? 'Submitting...'
                                                    : 'Post Results'}
                                                {(isLoading || uploading) && (
                                                    <Loader
                                                        scale={2}
                                                        color="white"
                                                    />
                                                )}
                                            </div>
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center gap-2 p-2 mx-auto">
                                    <p>Cancellation Reason</p>
                                    <div className="flex flex-wrap gap-2 p-2 border rounded-md">
                                        {chipsList?.map(
                                            (res: any, index: number) => {
                                                return (
                                                    <p
                                                        key={index}
                                                        className={`p-2 border rounded-full ${reasonIndex === index && 'bg-[#0d6efd] border-[#0d6efd] text-white'}`}
                                                        onClick={() =>
                                                            selectReason(res)
                                                        }
                                                    >
                                                        {res?.type}
                                                    </p>
                                                );
                                            }
                                        )}
                                    </div>
                                    {reason === 'Other' && (
                                        <input
                                            type="text"
                                            placeholder="discription"
                                            className="border rounded-md p-4 w-[95%]"
                                            value={description}
                                            onChange={(e) =>
                                                setDescription(e?.target?.value)
                                            }
                                        />
                                    )}
                                    <button
                                        disabled={
                                            updateStatusLoading || uploading
                                        }
                                        className="p-2 text-white bg-red-600 border rounded-md "
                                        onClick={() => submitReason()}
                                    >
                                        <div className="flex items-center justify-center gap-4">
                                            {updateStatusLoading
                                                ? 'Submitting...'
                                                : 'Submit'}
                                            {updateStatusLoading && (
                                                <Loader
                                                    scale={2}
                                                    color="white"
                                                />
                                            )}
                                        </div>
                                    </button>
                                </div>
                            )}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default UploadImgDialog;
