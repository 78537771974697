import { useEffect, useState } from 'react';
import PlayerHeader from '../../components/Common/PlayerHeader';
import wallet from '../../assets/wallet.png';
import Layout from '../../components/Common/Layout/Layout';
import moment from 'moment';
import { useUserData } from '../../Context/UserContext';
import { useGetWalletLogs } from '../../apis/hooks/useWallet';
import { toast } from 'react-toastify';
import Loader from '../../components/Common/Loader/Loader';
import { useGetAllBattlesTypes } from '../../apis/hooks/useBattlesType';
import noDataFound from '../../assets/noDataFound.png';
const History = () => {
    const { user } = useUserData();
    const [selectedTab, setSelectedTabs] = useState<any>({
        name: 'All',
        index: 0,
    });
    const { data: battlesType, isLoading: battleLoading } =
        useGetAllBattlesTypes();
    const { data, isLoading, mutate, error } = useGetWalletLogs();
    const [battlesTypes, setBattlesTypes] = useState([]);
    const [tabs, setTabs] = useState<any>([]);
    const [history, setHistory] = useState<any>(null);

    // Static tabs array split into two parts
    const staticTabsBefore = [{ name: 'All', index: 0 }];

    const getWalletLogs = async () => {
        let payload: any = {
            userId: user?.userdetails?.id,
        };

        if (
            selectedTab.name === 'Classic' ||
            selectedTab.name === 'Popular' ||
            selectedTab.name === 'Quick' ||
            selectedTab.name === 'MKG'
        ) {
            payload.type = 'battle';
            payload.battleTypeId = selectedTab.battleTypeId;
        }

        if (selectedTab.name === 'Wallet') {
            payload.type = 'wallet';
        }

        mutate(payload, {
            onSuccess: (res: any) => {
                if (res?.data?.error) {
                    toast(res?.data?.message ?? 'Unable to get history', {
                        type: 'error',
                    });
                } else if (res?.data) {
                    setHistory(res?.data || []);
                }
            },
            onError: (error: any) => {
                toast(error?.message ?? 'Something went wrong', {
                    type: 'error',
                });
            },
        });
    };

    useEffect(() => {
        if (battlesType?.length) {
            setBattlesTypes(battlesType);
            const dynamicTabs = battlesType?.map(
                (item: any, index: number) => ({
                    name: item?.battlesType,
                    index: staticTabsBefore.length + index,
                    battleTypeId: item?.id,
                })
            );
            const staticTabsAfter = [
                { name: 'Wallet', index: dynamicTabs?.length + 1 },
            ];

            // Combine static tabs with dynamic tabs in between
            const allTabs = [
                ...staticTabsBefore,
                ...dynamicTabs,
                ...staticTabsAfter,
            ];
            setTabs(allTabs);
        }
    }, [battlesType]);

    useEffect(() => {
        if (tabs.length) {
            getWalletLogs();
        }
    }, [tabs, selectedTab]);

    return (
        <Layout>
            <div className="mx-auto mt-[20px] w-[90%] ">
                <div className="flex items-center justify-start gap-2 overflow-x-auto tabs-container whitespace-nowrap">
                    {tabs?.map((tab: any) => (
                        <button
                            key={tab.index}
                            className={`py-[6px] px-7 text-[12px] font-semibold text-center rounded-full ${
                                selectedTab.index === tab.index
                                    ? 'text-white bg-[#0d6efd]'
                                    : 'border text-[#000]'
                            }`}
                            onClick={() => {
                                setSelectedTabs(tab);
                                setHistory([]);
                                getWalletLogs();
                            }}
                        >
                            {tab?.name ?? ''}
                        </button>
                    ))}
                </div>

                {(isLoading || battleLoading) && (
                    <div className="flex flex-col gap-8 items-center justify-center h-[300px]">
                        <Loader scale={4} />
                        <p>Please Wait. Loading...</p>
                    </div>
                )}

                {!isLoading && history?.length === 0 ? (
                    <div className="py-20 text-center">
                        <img src={noDataFound} alt="No Data" height={200} />
                    </div>
                ) : (
                    <div className="py-5">
                        {history?.map((res: any) => (
                            <div
                                key={res.id}
                                className="flex justify-center gap-2 py-2"
                            >
                                <div className="text-[10px] border-b border-r w-[80px] relative">
                                    <img
                                        src={wallet}
                                        className="w-[24px] h[24px] absolute top-[-20px] left-[-10px]"
                                    />
                                    <p className="text-blue-600">
                                        {moment(res?.created_at).format(
                                            'DD MMM YYYY hh:mm A'
                                        )}
                                    </p>

                                    {res?.withdraw ? (
                                        <p className="text-red-600">
                                            {res?.withdraw?.updated_at
                                                ? moment(
                                                      res.withdraw.updated_at
                                                  ).format(
                                                      'DD MMM YYYY hh:mm A'
                                                  )
                                                : 'N/A'}
                                        </p>
                                    ) : (
                                        <p className="text-red-600">
                                            {res?.updated_at
                                                ? moment(res.updated_at).format(
                                                      'DD MMM YYYY hh:mm A'
                                                  )
                                                : 'N/A'}
                                        </p>
                                    )}
                                </div>
                                <div className="flex justify-between border-b text-[14px] flex-1 ">
                                    <span className="my-auto font-bold ">
                                        {res?.description?.length > 40
                                            ? res?.description.slice(0, 40) +
                                              '...'
                                            : res?.description}
                                    </span>
                                </div>
                                <div className="text-[10px] border-b border-l w-[80px] content-center">
                                    <div
                                        className={`flex flex-col justify-center items-end font-semibold rounded-r-lg ${
                                            res?.type === 'CREDIT'
                                                ? 'text-green-600'
                                                : 'text-red-600'
                                        }`}
                                    >
                                        {/* Check if there is a withdrawal request */}
                                        {res?.withdraw ? (
                                            // Handle different statuses of the withdrawal request
                                            <>
                                                {/* {res?.withdraw?.status ===
                                                    'Pending' && (
                                                    <p className="items-center font-semibold text-center text-yellow-600">
                                                        Verification Pending
                                                    </p>
                                                )} */}
                                                {res?.withdraw?.status ===
                                                    'Rejected' && (
                                                    <>
                                                        <p className="items-center font-semibold text-center text-red-600">
                                                            Rejected
                                                        </p>
                                                        <span className="text-[12px]">
                                                            {res?.type ===
                                                            'CREDIT'
                                                                ? '(+)'
                                                                : '(-)'}
                                                            &nbsp;{res?.amount}
                                                        </span>
                                                        <span>
                                                            Balance:{' '}
                                                            {res?.balance}
                                                        </span>
                                                    </>
                                                )}
                                                {res?.withdraw?.status ===
                                                    'Approved' && (
                                                    <>
                                                        <p className="items-center font-semibold text-center text-green-600">
                                                            Approved
                                                        </p>
                                                        <span className="text-[12px]">
                                                            {res?.type ===
                                                            'CREDIT'
                                                                ? '(+)'
                                                                : '(-)'}
                                                            &nbsp;{res?.amount}
                                                        </span>
                                                        <span>
                                                            Balance:{' '}
                                                            {res?.balance}
                                                        </span>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            // Display status and amount if there is no withdrawal request
                                            <>
                                                {res?.status !== 'Approved' && (
                                                    <p
                                                        className={`${res?.status === 'Pending' ? 'text-yellow-600' : 'text-red-600'}`}
                                                    >
                                                        {res?.status}
                                                    </p>
                                                )}
                                                <span className="text-[12px]">
                                                    {res?.type === 'CREDIT'
                                                        ? '(+)'
                                                        : '(-)'}
                                                    &nbsp;{res?.amount}
                                                </span>
                                                <span>
                                                    Balance: {res?.balance}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default History;
